import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import styles from './input-number.module.scss';

interface InputNumberProps extends UseControllerProps {
  name: string,
  rules?: any | null,
  widthLabel?: string,
  unit?: string,
  inline?: boolean
  label?: string | null,
  heading?: 1 | 2 | 3 | null,
  placeholder?: string,
  defaultValue?: string,
  className?: string,
  min?: number,
  max?: number,
  handleChange?: (str: string) => void,
  handleFocus?: () => void,
  disabled?: boolean,
}

const InputNumber:React.FC<InputNumberProps> = ({
  name,
  control,
  rules,
  widthLabel = null,
  unit,
  inline = false,
  label = null,
  heading = null,
  placeholder,
  defaultValue = '',
  className,
  min,
  max,
  handleChange,
  handleFocus,
  disabled,
}) => {
  const {
    field: {
      value = '',
      ref,
      onChange,
      onBlur,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  function handlePressEnter(e: any) {
    if (e.key === 'Enter' && e.target) {
      e.target.blur();
    }
  }

  const inputProps: Record<string, any> = {};

  function getClassName(): string {
    let strClassName = styles['container-input'];
    if (className) strClassName += ` ${styles[className]}`;
    if (inline) strClassName += ` ${styles.inlines}`;
    if (heading) strClassName += ` ${styles[`h${heading}`]}`;
    if (unit) strClassName += ` ${styles.unit}`;
    return strClassName;
  }

  if (min) {
    inputProps.min = min;
  }

  if (max) {
    inputProps.max = max;
  }

  return (
    <div
      onKeyUp={(e) => handlePressEnter(e)}
      className={getClassName()}
    >
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <input
        ref={ref}
        disabled={disabled}
        type='number'
        value={value}
        onBlur={onBlur}
        onFocus={() => {
          if (typeof handleFocus === 'function') handleFocus();
        }}
        onChange={(e) => {
          if (handleChange) handleChange(e.target.value);
          onChange(e.target.value);
        }}
        className={styles.input}
        placeholder={placeholder}
        {...inputProps}
      />
      {unit && <span className={styles.unit}>{unit}</span>}
    </div>
  );
};

export default InputNumber;
