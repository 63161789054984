import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ImLink } from 'react-icons/im';
import { IExample } from '../../../../types/characteristics';
import MasonryGrid from '../../../../components/MasonryGrid';
import AnimatedText from '../../../../components/AnimatedText';
import styles from './examples.module.scss';

const variants = {
  hidden: {
    y: '140%',
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.6,
    },
  },
  visible: {
    y: [100, 0],
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.4,
    },
  },
  out: {
    y: 0,
    opacity: 0,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.3,
    },
  },
};

const Examples = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { characteristic } = useOutletContext<any>();
  const [exampleOpened, setExampleOpened] = useState<string | null>(null);
  return (
    <div className={styles.examples}>
      {characteristic.examples.length > 0 && (
        <MasonryGrid>
          {characteristic.examples.map((example: IExample) => (
            <div className={styles.example} key={`example-${example._id}`}>
              <div
                className={styles.containerImage}
                onClick={
                  () => setExampleOpened((state) => (state === example._id ? null : example._id))
                }
              >
                {example.picture && (
                  <img
                    alt={example.picture.alt}
                    src={`${process.env.REACT_APP_API_URL}/files/public/${example.picture.path}?token=${token}`}
                  />
                )}
              </div>
              <div className={styles.text}>
                <p className={styles.title}>{example.title}</p>
                <div
                  className={example._id === exampleOpened ? `${styles.toggle} ${styles.close}` : `${styles.toggle} ${styles.open}`}
                  onClick={
                    () => setExampleOpened((state) => (state === example._id ? null : example._id))
                  }
                >
                  <div />
                </div>
                <AnimatePresence>
                  {exampleOpened === example._id && (
                    <motion.div
                      initial="hidden"
                      animate={example._id === exampleOpened ? 'visible' : 'out'}
                    >
                      <AnimatedText variants={variants} type={'paragraph'} text={example.description} />
                    </motion.div>
                  )}
                  {(exampleOpened === example._id && example.url) && (
                    <a className={styles.url} href={`https://${example.url}`}>
                      <ImLink /><span>{example.url}</span>
                    </a>
                  )}
                </AnimatePresence>
              </div>
            </div>
          ))}
        </MasonryGrid>
      )}
    </div>
  );
};

export default Examples;
