import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loginImage from '../../assets/images/login.png';
import logo from '../../assets/images/logo.svg';
import { forgotPassword } from '../../actions/auth';
import EmailResetPwdForm from '../../components/form/AuthForm/EmailResetPwdForm';
import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/club');
  }, [authReducer.user]);

  function submitForgot(data: FieldValues) {
    forgotPassword(dispatch, data).then((res: any) => {
      if (res?.status === 200) {
        setMessage(
          'Un email permettant de changer votre mot de passe vous a été envoyé.',
        );
      }
    });
  }

  return (
    <div className={styles['forgot-password']}>
      <div className={styles.container}>
        <div>
          <h1>Mot de passe oublié</h1>
          <div className={styles.form}>
            <p>
              Après validation vous recevrez un email vous permettant de changer
              de mot de passe.
            </p>
            <EmailResetPwdForm
              submit={submitForgot}
              signinLink="/login"
              succeedMessage={message}
              errorMessage={authReducer.error}
            />
          </div>
        </div>
        <div className={styles.containerImage}>
          <img className={styles.logo} src={logo} alt="logo" />
          <img src={loginImage} alt="login" />
        </div>

      </div>
    </div>
  );
};

export default ForgotPassword;
