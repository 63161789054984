import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  CHARACTERISTICS_GET,
  CHARACTERISTIC_GET,
  CHARACTERISTIC_POST,
  CHARACTERISTIC_PUT,
  CHARACTERISTIC_DELETE,
  CHARACTERISTIC_LOADING,
  CHARACTERISTIC_ERROR,
} from './types';

const getCharacteristic = async (dispatch: Dispatch, id: string) => {
  const url = `/characteristics/${id}`;
  dispatch({
    type: CHARACTERISTIC_LOADING,
    payload: CHARACTERISTIC_GET,
  });
  dispatch({
    type: CHARACTERISTIC_GET,
    payload: null,
  });
  const response = await getData(CHARACTERISTIC_ERROR, url, dispatch, true);
  if (response.data?.characteristic) {
    dispatch({
      type: CHARACTERISTIC_GET,
      payload: response.data?.characteristic,
    });
  }
};

const getCharacteristics = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/characteristics${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: CHARACTERISTIC_LOADING,
    payload: CHARACTERISTICS_GET,
  });
  const response = await getData(CHARACTERISTIC_ERROR, url, dispatch, true);
  if (response.data?.characteristics) {
    dispatch({
      type: CHARACTERISTICS_GET,
      payload: response.data,
    });
  }
};

const createCharacteristic = async (dispatch: Dispatch, data: any) => {
  const url = '/characteristics';
  dispatch({
    type: CHARACTERISTIC_LOADING,
    payload: CHARACTERISTIC_POST,
  });
  const response: any = await postData(CHARACTERISTIC_ERROR, url, dispatch, data, true);
  if (response.data?.characteristic) {
    dispatch({
      type: CHARACTERISTIC_POST,
      payload: response.data,
    });
  }
  return response;
};

const updateCharacteristic = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedCharacteristic } = data;
  const url = `/characteristics/${_id}`;
  dispatch({
    type: CHARACTERISTIC_LOADING,
    payload: `${CHARACTERISTIC_PUT}-${_id}`,
  });
  const response = await putData(CHARACTERISTIC_ERROR, url, dispatch, updatedCharacteristic, true);
  if (response.data?.characteristic) {
    dispatch({
      type: CHARACTERISTIC_PUT,
      payload: response.data,
    });
  }
  return response;
};

const deleteCharacteristic = async (dispatch: Dispatch, data: any) => {
  const url = `/characteristics/${data._id}`;
  const response: any = await deleteData(CHARACTERISTIC_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: CHARACTERISTIC_DELETE,
      payload: {
        _id: data._id,
        sector: response.data.sector,
      },
    });
  }
  return response;
};

export {
  getCharacteristic,
  getCharacteristics,
  createCharacteristic,
  updateCharacteristic,
  deleteCharacteristic,
};
