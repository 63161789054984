import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import * as d3 from 'd3';
import { useSearchParams } from 'react-router-dom';
import { FaLocationCrosshairs } from 'react-icons/fa6';
import { ICharacteristic } from '../../types/characteristics';
import DataFrame from '../DataFrame';
import styles from './chart.module.scss';

// interface INode {
//   source: string,
//   x: number,
//   y: number,
//   val: number,
//   color: string,
// }

interface INode extends ICharacteristic {
  category: number,
}

const colorScale = ['#9784BB', '#6EA052', '#EBB057', '#3157A4'];

const spring = {
  type: 'spring',
  damping: 15,
  stiffness: 50,
};

const Chart = (
  {
    data,
    sectors,
    positioning,
    vision = false,
    small = false,
    animated = true,
  }:
  { data: INode[],
    sectors: string[],
    positioning?: any,
    vision?: boolean,
    small?: boolean,
    animated?: boolean,
  },
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const width = small ? 400 : 500;
  const height = small ? 400 : 500;
  const offsetCollision = small ? 20 : 25;
  // const data = d3.range(18).map((d, i) => ({
  //   radius: (Math.random() * 25) + 5,
  //   category: i % 4,
  //   x: width / 2,
  //   y: height / 2,
  // }));
  const [nodes, setNodes] = useState([]);

  const simulateDataviz = () => {
    const centers: any = [
      [(25 * width) / 100, (25 * height) / 100],
      [(75 * width) / 100, (25 * height) / 100],
      [(75 * width) / 100, (75 * height) / 100],
      [(25 * width) / 100, (75 * height) / 100],
    ];

    const simulation = d3.forceSimulation(positioning
      ? [positioning, ...data] as d3.SimulationNodeDatum[] : data as d3.SimulationNodeDatum[])
      .force('charge', d3.forceManyBody().strength(5))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('x', d3.forceX().x((d: any) => centers[d.category][0]))
      .force('y', d3.forceY().y((d: any) => centers[d.category][1]))
      .force('collision', d3.forceCollide().radius((d: any) => d.radius + offsetCollision));

    simulation.tick(300);

    return simulation;
  };

  useEffect(() => {
    let sim: any;
    const timer = setTimeout(() => {
      sim = simulateDataviz();
      setNodes(sim.nodes());
    }, animated ? 2000 : 100);
    return () => {
      if (sim) sim?.stop();
      clearTimeout(timer);
    };
  }, [positioning]);

  // HANDLE FADE IN/OUT NODE
  useEffect(() => {
    const sector = searchParams.get('sector');
    if (sector) {
      setNodes((state: any) => state.map((node: any) => (
        node.sector === sector ? { ...node, opacity: 1 } : { ...node, opacity: 0.2 })));
    } else {
      setNodes((state: any) => state.map((node: any) => ({ ...node, opacity: 1 })));
    }
  }, [searchParams]);

  const handleSelecSector = useCallback((id: string) => {
    if (vision) return;
    const params = new URLSearchParams(searchParams);
    const characteritic = params.get('sector');
    params.delete('characteristic');
    if (characteritic && characteritic === id) {
      params.delete('sector');
    } else {
      params.set('sector', id);
    }
    setSearchParams(params);
  }, [searchParams]);

  const handleSelectNode = useCallback((id: string) => {
    if (vision) return;
    const params = new URLSearchParams(searchParams);
    const characteritic = params.get('characteristic');
    params.delete('sector');
    if (characteritic && characteritic === id) {
      params.delete('characteristic');
    } else if (id) {
      params.set('characteristic', id);
    }
    setSearchParams(params);
  }, [searchParams]);

  return (
    <div className={styles.chart}>
      <div
        className={small ? `${styles.containerDataFrame} ${styles.small}` : styles.containerDataFrame}
      >
        <DataFrame sectors={sectors} animated={animated} />
      </div>
      <div
        className={styles.containerNodes}
        style={{
          width,
          height,
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '55%',
            height: '55%',
            // background: 'red',
            transform: 'translate(-10%, -10%)',
            borderRadius: '50% 0 0 0',
            cursor: 'pointer',
          }}
          onClick={() => handleSelecSector('64b7f773bf050d3d67a5a458')}
        />
        <div
          style={{
            position: 'absolute',
            right: '0',
            width: '55%',
            height: '55%',
            // background: 'yellow',
            transform: 'translate(10%, -10%)',
            borderRadius: '0 50% 0 0',
            cursor: 'pointer',
          }}
          onClick={() => handleSelecSector('64b694ae2b23cae71388d16b')}
        />
        <div
          style={{
            position: 'absolute',
            left: '0%',
            bottom: '0%',
            width: '55%',
            height: '55%',
            // background: 'purple',
            transform: 'translate(-10%, 10%)',
            borderRadius: '0 0 0 50%',
            cursor: 'pointer',
          }}
          onClick={() => handleSelecSector('64b7f762bf050d3d67a5a454')}
        />
        <div
          style={{
            position: 'absolute',
            right: '0%',
            bottom: '0%',
            width: '55%',
            height: '55%',
            // background: 'green',
            transform: 'translate(10%, 10%)',
            borderRadius: '0 0 50% 0',
            cursor: 'pointer',
          }}
          onClick={() => handleSelecSector('64b7f74bbf050d3d67a5a450')}
        />
        {nodes.map((d: any, index: number) => (
          <motion.div
            key={`circle-${index}`}
            className={styles.node}
            style={{
              background: d.positioning ? 'transparent' : colorScale[d.category],
              width: (d.radius * (small ? 0.8 : 1)) * 2,
              height: (d.radius * (small ? 0.8 : 1)) * 2,
              zIndex: d.positioning ? 3 : 'inherit',
            }}
            transition={spring}
            initial={{
              opacity: 0,
              x: (width / 2) - (d.radius * (small ? 0.8 : 1)),
              y: (height / 2) - (d.radius * (small ? 0.8 : 1)),
            }}
            animate={{
              opacity: d.opacity,
              x: d.x - (d.radius * (small ? 0.8 : 1)),
              y: d.y - (d.radius * (small ? 0.8 : 1)),
            }}
            onClick={() => handleSelectNode(d._id)}
          >
            {d.name && <label>{d.name}</label>}
            {d.positioning && <FaLocationCrosshairs size={27} />}
            {d.positioning && (
              <div className={styles.positionDetails}>
                {d.results.map((result: any) => (
                  <div key={`result-${result.sector}`} className={styles.result}>
                    <p style={{ color: result.color }}>
                      {result.sectorName} : {result.globalPercent}%
                    </p>
                  </div>
                ))}
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Chart;
