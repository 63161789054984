import React, { useEffect } from 'react';
import {
  ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';

// const data = [
//   { x: 100, y: 200, z: 200 },
//   { x: 120, y: 100, z: 260 },
//   { x: 170, y: 300, z: 400 },
//   { x: 140, y: 250, z: 280 },
//   { x: 150, y: 400, z: 500 },
//   { x: 110, y: 280, z: 200 },
// ];

const styles = {
  p: {
    fontSize: '12px',
    margin: 0,
    padding: '3px 5px',
    color: 'white',
  },
};

const CustomTooltip = (
  { active, payload } :
  { active: any, payload: any },
) => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');

  if (active && payload && payload.length) {
    return (
      <div style={{
        display: 'flex',
        background: 'white',
        border: 'solid 1px #000000',
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          backgroundSize: 'cover',
          backgroundImage: `url(${process.env.REACT_APP_API_URL}/files/public/${payload[0].payload?.picture?.path}?token=${token})`,
        }} />
        <div style={{
          display: 'flex',
          padding: '0px 4px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <p
            style={{
              // padding: '2px 4px',
              fontFamily: 'CenturyGothic-Bold',
              margin: '0',
            }}
          >{payload[0].payload.name}</p>
          <div>
            {payload.map((pld: any, index: number) => (
              <div key={`${pld.dataKey} ${index}`} style={{ display: 'inline-block' }}>
                <p style={{
                  padding: '0px 5px 0px 0px',
                  margin: '0',
                }}>
                  {pld.dataKey === 'future' ? 'Future' : 'Present'}: {pld.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const CustomDot = ({
  cx, cy, payload,
}: any) => (
  <g
    style={{ cursor: 'pointer' }}
    data-chart-x={cx}
    data-chart-y={cy}
    data-x-value={cx}
    data-y-value={cy}
    data-radius={20}
    onClick={() => {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: `/characteristics/${payload._id}`,
      }).click();
    }}
  >
    <circle cx={cx} cy={cy} r={10} fill={payload.sector.color} />
  </g>
);

const ComponentScatterChart = ({ data } : { data: any }) => {
  useEffect(() => {}, []);
  return (
    <div style={{
      position: 'relative',
      padding: '25px 0 20px 0',
      marginTop: '45px',
    }}>
      <div style={{
        position: 'absolute',
        zIndex: 3,
        top: '46%',
        left: '10px',
      }}>
        <p style={{ ...styles.p, color: '#414BB2' }}>Growing codes, Important for the future</p>
      </div>
      <div style={{
        position: 'absolute',
        zIndex: 3,
        top: '46%',
        left: '50.5%',
      }}>
        <p style={{ ...styles.p, color: '#8FD14F' }}>Important now and for the future</p>
      </div>
      <div style={{
        position: 'absolute',
        zIndex: 3,
        bottom: '32px',
        left: '10px',
      }}>
        <p style={{ ...styles.p, color: '#F24726' }}>Not important</p>
      </div>
      <div style={{
        position: 'absolute',
        zIndex: 3,
        bottom: '32px',
        left: '50.5%',
      }}>
        <p style={{ ...styles.p, color: '#FAC710' }}>Important now but not for the future</p>
      </div>
      <ResponsiveContainer width="100%" height={470}>
        <ScatterChart
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 8,
          }}
        >
          <CartesianGrid
            horizontalCoordinatesGenerator={
              ({ height }) => [(height / 2)]}
            verticalCoordinatesGenerator={
              ({ width }) => [(width / 2)]}
          />
          <XAxis
            height={1}
            type="number"
            dataKey="present"
            name="present"
            domain={[0, 10]}
            scale='linear'
            tickFormatter={() => ''}
            tick={false}
          />
          <YAxis
            width={1}
            type="number"
            dataKey="future"
            name="future"
            domain={[0, 10]}
            scale='linear'
            tickFormatter={() => ''}
            tick={false}
          />
          <Tooltip
            cursor={false}
            content={<CustomTooltip active={undefined} payload={undefined} />}
          />
          <Scatter
            data={data}
            shape={CustomDot}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ComponentScatterChart;
