import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  SECTORS_GET,
  SECTOR_GET,
  SECTOR_SET,
  SECTOR_LIST_GET,
  SECTOR_POST,
  SECTOR_PUT,
  SECTOR_DELETE,
  SECTOR_LOADING,
  SECTOR_ERROR,
} from './types';
import ISector from '../types/sector';

const getSector = async (dispatch: Dispatch, id: string) => {
  const url = `/sectors/${id}`;
  dispatch({
    type: SECTOR_LOADING,
    payload: SECTOR_GET,
  });
  dispatch({
    type: SECTOR_GET,
    payload: null,
  });
  const response = await getData(SECTOR_ERROR, url, dispatch, true);
  if (response.data?.sector) {
    dispatch({
      type: SECTOR_GET,
      payload: response.data?.sector,
    });
  }
};

const setSector = async (dispatch: Dispatch, sector: ISector | null) => {
  dispatch({
    type: SECTOR_SET,
    payload: sector,
  });
};

const getSectorList = async (dispatch: Dispatch) => {
  const url = '/sectors/list';
  dispatch({
    type: SECTOR_LOADING,
    payload: SECTOR_LIST_GET,
  });
  const response = await getData(SECTOR_ERROR, url, dispatch, true);
  if (response.data?.list) {
    dispatch({
      type: SECTOR_LIST_GET,
      payload: response.data?.list,
    });
  }
};

const getSectors = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/sectors${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: SECTOR_LOADING,
    payload: SECTORS_GET,
  });
  const response = await getData(SECTOR_ERROR, url, dispatch, true);
  if (response.data?.sectors) {
    dispatch({
      type: SECTORS_GET,
      payload: response.data,
    });
  }
};

const createSector = async (dispatch: Dispatch, data: any) => {
  const url = '/sectors';
  dispatch({
    type: SECTOR_LOADING,
    payload: SECTOR_POST,
  });
  const response: any = await postData(SECTOR_ERROR, url, dispatch, data, true);
  if (response.data?.sector) {
    dispatch({
      type: SECTOR_POST,
      payload: response.data.sector,
    });
  }
  return response;
};

const updateSector = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedSector } = data;
  const url = `/sectors/${_id}`;
  dispatch({
    type: SECTOR_LOADING,
    payload: `${SECTOR_PUT}-${_id}`,
  });
  const response = await putData(SECTOR_ERROR, url, dispatch, updatedSector, true);
  if (response.data?.sector) {
    dispatch({
      type: SECTOR_PUT,
      payload: response.data,
    });
  }
  return response;
};

const deleteSector = async (dispatch: Dispatch, data: any) => {
  const url = `/sectors/${data._id}`;
  const response: any = await deleteData(SECTOR_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: SECTOR_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export {
  getSector,
  setSector,
  getSectors,
  getSectorList,
  createSector,
  updateSector,
  deleteSector,
};
