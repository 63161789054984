import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ImLink } from 'react-icons/im';
import styles from './moodboard.module.scss';
import { IFile } from '../../../../types/files';

const Moodboard = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { sector } = useOutletContext<any>();

  const hasPrimaryConsumerTypologies = useMemo(() => (
    Object.values(sector.consumerTypologies.primary).filter((d) => d).length > 0
  ), [sector.consumerTypologies]);

  const hasSecondaryConsumerTypologies = useMemo(() => (
    Object.values(sector.consumerTypologies.secondary).filter((d) => d).length > 0
  ), [sector.consumerTypologies]);

  const renderImage = (picture: IFile) => {
    let img = (
      <img
        alt={picture.alt}
        src={`${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${token}`}
      />
    );
    if (picture.url) {
      img = (
        <a href={`https://${picture.url}`}>
          {img}
          <div className={styles.icon}>
            <ImLink />
          </div>
        </a>
      );
    }
    return (
      <div key={`image-${picture._id}`}>
        {img}
      </div>
    );
  };

  return (
    <div className={styles.moodboard}>
      {sector.moodboard.length > 0 && (
        <div className={`${styles.moodboard} ${styles[`grid${sector.moodboard.length}`]}`}>
          {sector.moodboard.map((picture: IFile) => renderImage(picture))}
        </div>
      )}
      {sector.keyTakeOuts.length > 0 && (
        <div className={styles.keyTakeOuts}>
          <h5>Key take outs</h5>
          {sector.keyTakeOuts.map((d: { value: string, _id: string }) => (
            <div className={styles.item} key={`keyTakeOut-${d._id}`}>
              <p>{d.value}</p>
            </div>
          ))}
        </div>
      )}
      {(hasPrimaryConsumerTypologies || hasSecondaryConsumerTypologies) && (
        <>
          <h5>Main consumer typologies</h5>
          <div className={styles.consumerTypologies}>
            {hasPrimaryConsumerTypologies ? (
              <div className={styles.consumerTypology}>
                <label>Primary</label>
                {sector.consumerTypologies.primary.picture && (
                  <div className={styles.containerImage}>
                    <img
                      alt={sector.consumerTypologies.primary.picture.alt}
                      src={`${process.env.REACT_APP_API_URL}/files/public/${sector.consumerTypologies.primary.picture.path}?token=${token}`}
                    />
                  </div>
                )}
                {sector.consumerTypologies.primary.title && (
                  <p className={styles.title}>{sector.consumerTypologies.primary.title}</p>
                )}
                { sector.consumerTypologies.primary.signaling && (
                  <p>
                    <span>Signaling&nbsp;: </span>
                    {sector.consumerTypologies.primary.signaling}
                  </p>
                )}
              </div>
            ) : <div />}
            {hasPrimaryConsumerTypologies ? (
              <div className={styles.consumerTypology}>
                <label>Secondary</label>
                {sector.consumerTypologies.secondary.picture && (
                  <div className={styles.containerImage}>
                    <img
                      alt={sector.consumerTypologies.secondary.picture.alt}
                      src={`${process.env.REACT_APP_API_URL}/files/public/${sector.consumerTypologies.secondary.picture.path}?token=${token}`}
                    />
                  </div>
                )}
                {sector.consumerTypologies.secondary.title && (
                  <p className={styles.title}>{sector.consumerTypologies.secondary.title}</p>
                )}
                { sector.consumerTypologies.secondary.signaling && (
                  <p>
                    <span>Signaling&nbsp;: </span>
                    {sector.consumerTypologies.secondary.signaling}
                  </p>
                )}
              </div>
            ) : <div/>}
          </div>
        </>
      )}
    </div>
  );
};

export default Moodboard;
