import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import ISector from '../../types/sector';
import { ICharacteristic, ICharacteristicExtended } from '../../types/characteristics';
import { SECTORS_GET } from '../../actions/types';
import { getSectors } from '../../actions/sectors';
import Loader from '../../components/Loader';
import styles from './quiz.module.scss';

const indexes: Record<string, number> = {
  '64b7f773bf050d3d67a5a458': 0,
  '64b694ae2b23cae71388d16b': 1,
  '64b7f74bbf050d3d67a5a450': 2,
  '64b7f762bf050d3d67a5a454': 3,
};

const Quiz = () => {
  const dispatch = useDispatch();
  const { authReducer, sectorsReducer } = useSelector((d: any) => d);
  const { sectors, isLoading } = sectorsReducer;
  const { user } = authReducer;
  const quiz = user?.quiz || {};
  const [characteristics, setCharacteristics] = useState<ICharacteristicExtended[]>([]);
  const [answers, setAnswers] = useState<any>({});

  useEffect(() => {
    setAnswers(quiz);
  }, [quiz]);

  // GET SECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    } else if (sectors.length > 0 && !isLoading.includes(SECTORS_GET)) {
      const items: ICharacteristicExtended[] = [];
      sectors.forEach((sector: ISector) => {
        sector.characteristics.forEach((characteristic: ICharacteristic) => {
          items.push({
            ...characteristic,
            present: answers[characteristic._id]?.present
              ? answers[characteristic._id].present : null,
            future: answers[characteristic._id]?.future
              ? answers[characteristic._id].future : null,
            sector: {
              _id: sector._id,
              name: sector.name,
              color: sector.color,
            },
            category: indexes[sector._id] || 0,
            radius: (characteristic.rank || 1) * 7,
            opacity: 1,
          });
        });
      });
      setCharacteristics(items);
    }
  }, [sectors, answers]);

  return (
    <div className={styles.quiz}>
      <div className={styles.container}>
          {isLoading.includes(SECTORS_GET) && (
            <div className={styles.loader}><Loader /></div>
          )}
          {sectors.length > 0 && (
            <Outlet context={{
              user,
              indexes,
              characteristics,
              sectors,
              answers,
              setAnswers,
            }}/>
          )}
      </div>
    </div>
  );
};

export default Quiz;
