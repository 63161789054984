import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import putQuiz from '../../../actions/user';
import styles from './page.module.scss';

const itemPerPage = 3;

const PageQuiz = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const {
    characteristics = [],
    answers,
    setAnswers,
  } = useOutletContext<any>();
  const [items, setItems] = useState<any[]>([]);
  const [isMovingForward, setIsMovingForward] = useState(true);
  const currentPage = parseInt(page || '1', 10);

  useEffect(() => {
    if (Number.isNaN(currentPage)) navigate('/brand-assessment');
  }, [currentPage]);

  useEffect(() => {
    const currentItems = characteristics
      .slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage);
    setItems(currentItems);
  }, [characteristics, currentPage]);

  const totalPage = useMemo(
    () => Math.ceil((characteristics?.length || 0) / itemPerPage),
    [characteristics],
  );

  const lastCompletePage = useMemo(() => {
    if (!characteristics.length) return null;
    let res = 0;
    let index = 0;
    while (characteristics[index]?.present && characteristics[index]?.future) {
      index += 1;
    }
    res = Math.floor(index / itemPerPage);
    return res;
  }, [characteristics]);

  // PREVENT ACCESS TO PAGE WHEN PREVIOUS NOTATIONS
  // AREN'T COMPLETED
  useEffect(() => {
    if (typeof lastCompletePage !== 'number') return;
    if (lastCompletePage + 1 < currentPage) navigate(`/brand-assessment/${(lastCompletePage + 1)}`);
  }, [lastCompletePage]);

  const isValid = useMemo(() => (
    items.every((item: any) => item.present !== null && item.future !== null)
  ), [items]);

  const handleNext = () => {
    const nextIndex = currentPage + 1;
    if (!lastCompletePage || !isValid) return;
    if (currentPage === totalPage) {
      navigate('/brand-assessment/vision');
    } else {
      navigate(`/brand-assessment/${nextIndex}`);
    }
    setIsMovingForward(true);
  };

  const handleBack = () => {
    const prevIndex = currentPage - 1;
    navigate(`/brand-assessment/${prevIndex}`);
    setIsMovingForward(false);
  };

  // eslint-disable-next-line max-len
  const handleAnswer = useCallback(async (characteristicId: string, note: number, moment: string) => {
    const result = await putQuiz({
      ...(answers),
      [characteristicId]: {
        ...(answers[characteristicId] || {}),
        [moment]: note,
      },
    });
    if (result) setAnswers(result);
  }, [answers]);

  return (
    <div className={styles.quizPage}>
      <div className={styles.navigation}>
        <h2>Brand Assessment</h2>
        {characteristics?.length && (
          <ul>
            {new Array(totalPage).fill(0).map((_, index) => (
              <li
              key={index.toString()}
              className={currentPage === (index + 1) ? styles.active : ''}
              // eslint-disable-next-line max-len
              style={typeof lastCompletePage === 'number' && lastCompletePage >= index
                ? {} : { pointerEvents: 'none', cursor: 'not-allowed' }}
              onClick={() => {
                setIsMovingForward(!(index + 1 < currentPage));
                navigate(`/brand-assessment/${index + 1}`);
              }} />
            ))}
          </ul>
        )}
      </div>
      <motion.div
        key={`${page}-answer`}
        initial={{
          opacity: 0,
          x: isMovingForward ? -100 : 100,
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        transition={{
          // ease: 'easeInOut',
          duration: 0.4,
        }}
      >
        {items.length && items.map((item: any) => (
          <div key={item._id} className={styles.characteristic}>
            <div>
              <h3>{item.name}</h3>
              <h5 style={{ color: item.sector.color }} >{item.sector.name}</h5>
              <div className={styles.content}>
                <div
                  className={styles.characteristicImg}
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URL}/files/public/${item?.picture?.path}?token=${token})`,
                  }}
                />
                <p>
                  {item.description}
                </p>
              </div>
            </div>
            <div className={styles.notations}>
              <div className={styles.notation}>
                <label>Present</label>
                {new Array(10).fill(0).map((_, index) => (
                  <div
                    key={`presence-${index}`}
                    className={`${styles.note} ${answers[item._id]?.present === index + 1 ? styles.selected : ''}`}
                    onClick={() => handleAnswer(item._id, index + 1, 'present')}
                  >
                    <p>{index + 1}</p>
                  </div>
                ))}
              </div>
              <div className={styles.notation}>
                <label>Future</label>
                {new Array(10).fill(0).map((_, index) => (
                  <div
                    key={`presence-${index}`}
                    className={`${styles.note} ${answers[item._id]?.future === index + 1 ? styles.selected : ''}`}
                    onClick={() => handleAnswer(item._id, index + 1, 'future')}
                  >
                    <p>{index + 1}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </motion.div>
      <div className={styles.containerButton}>
        <button
          className={currentPage <= 1 ? styles.disabled : ''}
          onClick={() => {
            if (currentPage <= 1) return;
            handleBack();
          }}
        >
          Back
        </button>
        <button
          className={(!isValid) ? styles.disabled : ''}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PageQuiz;
