import React from 'react';
import { useFieldArray, useFormContext, Control } from 'react-hook-form';
import { GrFormClose } from 'react-icons/gr';
import { RiAddLine } from 'react-icons/ri';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { InputText } from '../../../../lib/HooksFormFields';
import styles from './country-revelance.module.scss';

const Country = ({ name, title, control } : { name: string, title: string, control: Control }) => {
  const {
    fields, append, remove,
  } = useFieldArray({
    name,
    control,
  });

  return (
    <div className={styles.country}>
      <div className={styles.containerFlag}>
        <div className={styles.flag}>
          <FlagIcon code={name.toUpperCase() as FlagIconCode} />
        </div>
      </div>
      <div className={styles.container}>
        <label>{title}</label>
        {fields.map((field, index) => (
            <div className={styles.containerField} key={field.id}>
            <InputText
                name={`${name}.${index}.value`}
                control={control}
            />
            <div
                className={styles.remove}
                onClick={() => remove(index)}
            >
                <GrFormClose />
            </div>
            </div>
        ))}
        <button
          className={styles.append}
          onClick={() => append({ value: '' })}
        >
          <span>Ajouter une ligne</span>
          <div className={styles.icon}>
            <RiAddLine />
          </div>
        </button>
      </div>
    </div>
  );
};

const CountryRevelance = () => {
  const {
    control,
  } = useFormContext();

  return (
    <div className={styles.countryRevelance}>
      <Country control={control} name="fr" title="France"/>
      <Country control={control} name="us" title="USA"/>
      <Country control={control} name="cn" title="CHINA"/>
    </div>
  );
};

export default CountryRevelance;
