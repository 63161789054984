import React from 'react';
import { motion } from 'framer-motion';

// Map API "type" vaules to JSX tag names
const tagMap: { [key: string]: string } = {
  paragraph: 'p',
  button: 'button',
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
};

// Framer Motion variant object, for controlling animation
const item = {
  hidden: {
    y: '140%',
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.6,
    },
  },
  visible: {
    y: [100, 0],
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.4,
    },
  },
  out: {
    y: 0,
    opacity: 0,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.3,
    },
  },
};

// AnimatedCharacters
// Handles the deconstruction of each word and character to setup for the
// individual character animations
const AnimatedCharacters = ({
  text,
  type,
  variants = item,
  isMultilines = true,
}: {
  text: string;
  type: string;
  variants: any;
  isMultilines?: boolean;
}) => {
  //  Split each word of props.text into an array
  const splitWords = text.split(' ');
  // Create storage array
  let words: string[] = [];
  // Add a space ("\u00A0") to the end of each word
  words = isMultilines ? splitWords.map((word) => `${word}\u00A0`) : [text];

  // Get the tag name from tagMap
  const Tag: any = tagMap[type];

  return (
    <Tag style={{ position: 'relative' }}>
      {words.map((word, index) => (
          // Wrap each word in the Wrapper component
          <span
              key={index}
              style={{
                overflow: 'hidden',
                display: 'inline-block',
                lineHeight: type === 'paragraph' ? '1.5' : 'auto',
              }}
            >
              <motion.span
                style={{
                  display: 'inline-block',
                  willChange: 'transform opacity',
                }}
                variants={variants}
              >
                {word}
              </motion.span>
            </span>
      ))}
      {/* {} */}
    </Tag>
  );
};

export default AnimatedCharacters;
