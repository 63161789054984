import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate, useLocation, useSearchParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';
import Layout from './components/Layout';
import Loader from './components/Loader';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import SignIn from './pages/signin';
import LuxuryCodes from './pages/luxury-codes';

import CharacteristicEdit from './pages/characteristic-edit';
import CharacteristicEditDescription from './pages/characteristic-edit/sections/description';
import CharacteristicEditIntroduction from './pages/characteristic-edit/sections/introduction';
import CharacteristicEditMoodboard from './pages/characteristic-edit/sections/moodboard';
import CharacteristicEditExamples from './pages/characteristic-edit/sections/examples';
import CharacteristicEditView from './pages/characteristic-edit/sections/view';

import SectorEdit from './pages/sector-edit';
import SectorEditDescription from './pages/sector-edit/sections/description';
import SectorEditIntroduction from './pages/sector-edit/sections/introduction';
import SectorEditMoodboard from './pages/sector-edit/sections/moodboard';
import SectorEditCountryRelevance from './pages/sector-edit/sections/country-revelance';

import Dataviz from './pages/dataviz';

import Characteristic from './pages/characteritic';
import CharacteristicContainerScroll from './pages/characteritic/ContainerScroll';

import Sector from './pages/sector';
import SectorContainerScroll from './pages/sector/ContainerScroll';

import Quiz from './pages/quiz';
import QuizStart from './pages/quiz/start';
import QuizPage from './pages/quiz/page';
import QuizVision from './pages/quiz/vision';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let token = localStorage.getItem('token');
    const params = new URLSearchParams(searchParams);
    const tokenParams = params.get('token');

    //* SET NEW TOKEN IF PARAM TOKEN
    if (!token && tokenParams) {
      token = `JWT ${tokenParams}`;
      localStorage.setItem('token', `${token}`);
      params.delete('token');
      setSearchParams(params);
    }

    if (token) {
      getProfileAction(dispatch).then(() => {
        if ([
          '',
          '/',
          '/sso',
          '/login',
          '/forgot-password',
          '/reset-password',
        ].includes(location.pathname)) {
          navigate('/luxury-codes');
        }
      }).catch(() => {
        params.delete('token');
        navigate('/login');
      });
    } else navigate('/login');
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { user } = useSelector((d: any) => d.authReducer);

  return (
    <Routes>
      <Route
        path="*"
        element={(<Layout key="app">
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {user && (
              <Route
                path="*"
                element={(
                  <Routes>
                    <Route path="luxury-codes" element={<LuxuryCodes />} />
                    {user.role === 'Admin' && (
                      <>
                        <Route path="characteristic-edit/:id?" element={<CharacteristicEdit />}>
                          <Route index element={<CharacteristicEditDescription />} />
                          <Route path="description" element={<CharacteristicEditDescription />} />
                          <Route path="introduction" element={<CharacteristicEditIntroduction />} />
                          <Route path="moodboard" element={<CharacteristicEditMoodboard />} />
                          <Route path="examples" element={<CharacteristicEditExamples />} />
                          <Route path="consumer-connect" element={<CharacteristicEditView />} />
                        </Route>
                        <Route path="sector-edit/:id?" element={<SectorEdit />}>
                          <Route index element={<SectorEditDescription />} /><Route path="description" element={<SectorEditDescription />} />
                          <Route path="introduction" element={<SectorEditIntroduction />} />
                          <Route path="moodboard" element={<SectorEditMoodboard />} />
                          <Route path="country-revelance" element={<SectorEditCountryRelevance />} />
                        </Route>
                      </>
                    )}
                    <Route path="visualization" element={<Dataviz />} />
                    <Route path="characteristics/:id/:page?" element={<Characteristic />}>
                      <Route index element={<CharacteristicContainerScroll />} />
                    </Route>
                    <Route path="sectors/:id/:page?" element={<Sector />}>
                      <Route index element={<SectorContainerScroll />} />
                    </Route>
                    <Route path="brand-assessment" element={<Quiz />}>
                      <Route index element={<QuizStart />} />
                      <Route path="vision" element={<QuizVision />} />
                      <Route path=":page" element={<QuizPage />} />
                    </Route>
                    <Route path="*" element={<LuxuryCodes />} />
                  </Routes>
                )}
              />
            )}
            <Route path="/sso" element={<Redirect />} />
            <Route path="*" element={<Redirect />} />
          </Routes>
        </Layout>)}
      />
    </Routes>
  );
};

export default AppRoutes;
