import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Outlet, useNavigate, useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  useForm, FormProvider, useFormContext, FieldValues,
} from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import { SECTORS_GET, CHARACTERISTIC_POST, CHARACTERISTIC_PUT } from '../../actions/types';
import { createCharacteristic, updateCharacteristic } from '../../actions/characteristics';
import { getSectors, getSectorList } from '../../actions/sectors';
import styles from './characteristic-edit.module.scss';
import ISector from '../../types/sector';
import { IFile } from '../../types/files';
import { ICharacteristic, IExample } from '../../types/characteristics';
import CharacteristicNav from '../../components/CharacteristicNav';
import Loader from '../../components/Loader';

interface ICharacteristicWithSector extends ICharacteristic {
  sector: string,
}

const ContainerTitle = (
  {
    submit,
    isLoading,
    characteristicID,
  } : {
    submit: (data: FieldValues) => Promise<void>,
    isLoading: string[],
    characteristicID?: string,
  },
) => {
  const navigate = useNavigate();
  const {
    formState: { isValid, isDirty },
    watch,
    handleSubmit,
  } = useFormContext();
  const name = watch('name');
  const description = watch('description');
  const sector = watch('sector');

  // eslint-disable-next-line max-len
  const asMinimumRequirement = useMemo(() => (!!((name && description && sector))), [name, description, sector]);

  return (
    <div className={styles.containerTitle}>
      <div className={styles.back} onClick={() => {
        if (characteristicID) navigate(`/characteristics/${characteristicID}`);
        else navigate('/luxury-codes');
      }}>
        <BsArrowLeft size={32} />
      </div>
      <h1>{watch('name') || '...'}</h1>
      <button
        onClick={handleSubmit(submit)}
        disabled={!isValid || !asMinimumRequirement || !isDirty}
        className={(!isValid || !asMinimumRequirement || !isDirty) ? 'disabled' : ''}
      >
        Save the characteristic
      </button>
      {((characteristicID && isLoading.includes(`${CHARACTERISTIC_PUT}-${characteristicID}`))
      || (!characteristicID && isLoading.includes(CHARACTERISTIC_POST))) && (
        <div className={styles.containerLoader}>
          <Loader small />
        </div>
      )}
    </div>
  );
};

const CharacteristicEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const characteristicID = params.id;
  const dispatch = useDispatch();
  const {
    list, changedAt, sectors, isLoading,
  } = useSelector((state: any) => state.sectorsReducer);
  const { isLoading: isCharacteristicsLoading } = useSelector(
    (state: any) => state.characteristicsReducer,
  );

  const themeReducer = useSelector((d: any) => d.themeReducer);
  const { tonic } = themeReducer;
  const [characteristic, setCharacteristic] = useState<ICharacteristicWithSector>();

  const methods = useForm({
    defaultValues: {
      examples: [],
    } as FieldValues,
  });

  // GET VECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    }
  }, [sectors]);

  useEffect(() => {
    getSectorList(dispatch);
  }, []);

  useEffect(() => {
    if (!characteristicID) return;
    const characteristics: ICharacteristicWithSector[] = [];
    sectors.forEach((sector: ISector) => {
      sector.characteristics.forEach((c: ICharacteristic) => {
        characteristics.push({
          ...c,
          sector: sector._id,
        });
      });
    });

    const found: ICharacteristicWithSector | undefined = characteristics
      .find((c) => c._id === characteristicID);

    if (!found) return;
    setCharacteristic(found);
    const sector = sectors.find((s: ISector) => s._id === found.sector);
    dispatch({
      type: 'THEME_SET_TONIC',
      payload: sector.color,
    });
  }, [changedAt, sectors, characteristicID]);

  // INIT FORM
  useEffect(() => {
    if (!characteristic) return;

    methods.reset({
      name: characteristic.name,
      description: characteristic.description || '',
      introduction: characteristic.introduction || '',
      picture: characteristic?.picture?._id,
      cover: characteristic?.cover?._id,
      video: characteristic?.video?._id,
      rank: characteristic.rank,
      sector: characteristic.sector,
      percents: characteristic.percents,
      examples: characteristic.examples
        ? characteristic.examples.map((e: IExample) => ({
          ...e,
          picture: e.picture?._id,
        }))
        : [],
      moodboard: characteristic.moodboard
        ? characteristic.moodboard.map((e: IFile) => ({
          picture: e?._id,
        }))
        : [],
      socioCulturals: characteristic.socioCulturals,
      signalsCommunicated: characteristic.signalsCommunicated,
      semioticSocials: characteristic.semioticSocials,
      from: characteristic.from || '',
      to: characteristic.to || '',
      view: {
        ...characteristic.view,
        picture: characteristic?.view?.picture?._id || null,
      },
    });
  }, [characteristic?.updatedAt]);

  const submit = async (data: FieldValues) => {
    if (!characteristicID) {
      const res = await createCharacteristic(dispatch, data);
      if (res.status === 201) {
        navigate(`/characteristic-edit/${res.data.characteristic._id}`);
      }
    } else {
      const moodboard = data.moodboard?.map((e: any) => e.picture);
      updateCharacteristic(dispatch, {
        _id: characteristicID,
        ...data,
        moodboard,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.edit}>
        <div className={styles.sidebar}>
          <ContainerTitle
            submit={submit}
            characteristicID={characteristicID}
            isLoading={isCharacteristicsLoading}
          />
          <CharacteristicNav
            characteristicID={characteristicID}
            baseUrl={characteristic?._id ? `/characteristic-edit/${characteristicID}` : '/characteristic-edit'}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.form}>
            {sectors.length > 0 && (
              <Outlet context={{
                tonic,
                characteristic: characteristic?._id,
                list,
                submit,
              }} />
            )}
            {isLoading.includes(SECTORS_GET) && (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default CharacteristicEdit;
