import { Dispatch } from 'redux';
import {
  postData,
  getData,
} from './index';
import {
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  AUTH_GET_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
} from './types';

interface RequestFunction {
  (
    dispatch: Dispatch,
    data?: any,
  ) : any
}

export const signUp: RequestFunction = async (dispatch, data) => {
  const url = '/auth/register';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response?.data?.user;
  const token = response?.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNUP,
      payload: { user, token },
    });
  }
  return response;
};

export const signIn: RequestFunction = async (dispatch, data) => {
  const url = '/auth/login';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response?.data?.user;
  const token = response?.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return response;
};

export const signSamlIn: RequestFunction = async (dispatch) => {
  const url = '/auth/login-saml/user';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  const user = response?.data?.user;
  const token = response?.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return response;
};

export const forgotPassword: RequestFunction = async (dispatch, data) => {
  const url = '/auth/forgot-password';
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  return response;
};

export const resetPassword: RequestFunction = async (dispatch, data) => {
  const url = `/auth/reset-password/${data.token}`;
  const response = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false);
  return response;
};

export const logout = (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};

export const getProfileAction = async (dispatch: Dispatch) => {
  const url = '/users/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: response.data,
    });
  }
};

export const resetError = (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};
