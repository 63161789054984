import React, { useEffect, useState } from 'react';
import {
  NavLink,
  Outlet,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { SECTORS_GET } from '../../actions/types';
import { getSectors } from '../../actions/sectors';
import styles from './characteristic.module.scss';
import ISector from '../../types/sector';
import { ICharacteristic } from '../../types/characteristics';
import Loader from '../../components/Loader';
import CharacteristicNav from '../../components/CharacteristicNav';

interface ICharacteristicWithSector extends ICharacteristic {
  sector: string,
}
const Characteristic = () => {
  const navigate = useNavigate();
  const params = useParams();
  const characteristicID = params.id;
  const dispatch = useDispatch();
  const { authReducer, sectorsReducer } = useSelector((state: any) => state);
  const { user } = authReducer;
  const { changedAt, sectors, isLoading } = sectorsReducer;
  const [characteristic, setCharacteristic] = useState<ICharacteristicWithSector>();
  const [sector, setSector] = useState<ISector>();

  // GET VECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    }
  }, [sectors]);

  useEffect(() => {
    if (characteristicID) {
      const characteristics: ICharacteristicWithSector[] = [];
      sectors.forEach((s: ISector) => {
        s.characteristics.forEach((c: ICharacteristic) => {
          characteristics.push({
            ...c,
            sector: s._id,
          });
        });
      });

      const found: ICharacteristicWithSector | undefined = characteristics
        .find((c) => c._id === characteristicID);

      if (!found) return;
      setCharacteristic(found);
      const foundSector = sectors.find((s: ISector) => s._id === found.sector);
      setSector(foundSector);
      dispatch({
        type: 'THEME_SET_TONIC',
        payload: foundSector.color,
      });
    }
  }, [changedAt, sectors, characteristicID]);

  return (
    <div className={styles.characteristic}>
      <div className={styles.sidebar}>
        <div className={styles.containerTitle}>
          <div className={styles.back} onClick={() => navigate('/visualization')}>
            <BsArrowLeft size={32} />
          </div>
          <h1>{characteristic?.name}</h1>
          {user.role === 'Admin' && (
            <NavLink to={`/characteristic-edit/${characteristicID}`}>
              <button>Edit the characteristic</button>
            </NavLink>
          )}
        </div>
        <CharacteristicNav
          editable={false}
          baseUrl={`/characteristics/${characteristicID}`}
          isCharacteristicLoad={!!characteristic}
        />
      </div>
      <div
        id='containerScroll'
        className={styles.container}
      >
        {isLoading.includes(SECTORS_GET) && (
          <div className={styles.loader}><Loader /></div>
        )}
        <div
          className={styles.content}
        >
          {characteristic && (
            <Outlet context={{ sector, characteristic }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Characteristic;
