// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

// ACTIONS SECTORS
export const SECTORS_GET = 'SECTORS_GET';
export const SECTOR_GET = 'SECTOR_GET';
export const SECTOR_SET = 'SECTOR_SET';
export const SECTOR_LIST_GET = 'SECTOR_LIST_GET';
export const SECTOR_POST = 'SECTOR_POST';
export const SECTOR_PUT = 'SECTOR_PUT';
export const SECTOR_DELETE = 'SECTOR_DELETE';
export const SECTOR_LOADING = 'SECTOR_LOADING';
export const SECTOR_ERROR = 'SECTOR_ERROR';

// ACTIONS CHARACTERISTICS
export const CHARACTERISTICS_GET = 'CHARACTERISTICS_GET';
export const CHARACTERISTIC_GET = 'CHARACTERISTIC_GET';
export const CHARACTERISTIC_POST = 'CHARACTERISTIC_POST';
export const CHARACTERISTIC_PUT = 'CHARACTERISTIC_PUT';
export const CHARACTERISTIC_DELETE = 'CHARACTERISTIC_DELETE';
export const CHARACTERISTIC_LOADING = 'CHARACTERISTIC_LOADING';
export const CHARACTERISTIC_ERROR = 'CHARACTERISTIC_ERROR';

// ACTIONS FILES
export const FILE_ERROR = 'FILE_ERROR';
