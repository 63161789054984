import React from 'react';
import { useOutletContext } from 'react-router-dom';
import styles from './view.module.scss';

// /consumer-connect

const ConsumerConnect = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { characteristic } = useOutletContext<any>();

  if (!characteristic?.view?.picture) return null;

  return (
    <div
      className={styles.view}
      style={characteristic?.view?.picture ? {
        backgroundImage: `url(${process.env.REACT_APP_API_URL}/files/public/${characteristic.view.picture.path}?token=${token})`,
        // height: `${height}px`,
      } : {
        // height: `${height}px`,
      }}
    >
      <div className={styles.container}>
        {(characteristic.view?.consumer.quote || characteristic.view?.consumer.source) && (
          <div>
            <h5>Consumer connection</h5>
            {characteristic.view?.consumer.quote && (
              <p>{characteristic.view.consumer.quote}</p>
            )}
            {characteristic.view?.consumer.source && (
              <p className={styles.source}>{characteristic.view.consumer.source}</p>
            )}
          </div>
        )}

        {(characteristic.view?.expert.quote || characteristic.view?.expert.source) && (
          <div>
            <h5>Expert view</h5>
            {characteristic.view?.expert.quote && (
              <p>{characteristic.view.expert.quote}</p>
            )}
            {characteristic.view?.expert.source && (
              <p className={styles.source}>{characteristic.view.expert.source}</p>
            )}
          </div>
        )}
      </div>

    </div>
  );
};

export default ConsumerConnect;
