import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormContext, FieldValues } from 'react-hook-form';
import { InputText, Textarea, InputMedia } from '../../../../lib/HooksFormFields';
import styles from './view.module.scss';
import { set } from '../../../../utils';

const View = () => {
  const { characteristic, submit } = useOutletContext<any>();
  const {
    getValues,
    control,
    watch,
  } = useFormContext();

  return (
    <div className={styles.view}>
        <label>Image illustration</label>
        <div className={styles.containerField}>
            <InputMedia
              name='view.picture'
              value={watch('view.picture')}
              disabled={!characteristic}
              btnText='Choose picture'
              submit={(obj: FieldValues, name, value) => {
                const data = set(
                  { consumerTypologies: getValues('view'), ...getValues() },
                  name,
                  value,
                );
                submit(data);
              }}
            />
        </div>
        <div className={styles.gridTwo}>
            <div>
                <label>Consumer connection</label>
                <div className={styles.containerField}>
                    <Textarea
                        name="view.consumer.quote"
                        placeholder='...'
                        control={control}
                    />
                </div>
                <div className={styles.containerField}>
                    <InputText
                        name="view.consumer.source"
                        placeholder='Source...'
                        control={control}
                    />
                </div>
                </div>
            <div>
                <label>Expert view</label>
                <div className={styles.containerField}>
                    <Textarea
                        name="view.expert.quote"
                        placeholder='...'
                        control={control}
                    />
                </div>
                <div className={styles.containerField}>
                    <InputText
                        name="view.expert.source"
                        placeholder='Source...'
                        control={control}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default View;
