import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useNavigate,
  Outlet,
  useParams,
} from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { SECTORS_GET } from '../../actions/types';
import { getSectors } from '../../actions/sectors';
import styles from './sector.module.scss';
import ISector from '../../types/sector';
import SectorNav from '../../components/SectorNav';
import Loader from '../../components/Loader';

const Characteristic = () => {
  const navigate = useNavigate();
  const params = useParams();
  const sectorID = params.id;
  const dispatch = useDispatch();
  const { authReducer, sectorsReducer } = useSelector((state: any) => state);
  const { user } = authReducer;
  const { changedAt, sectors, isLoading } = sectorsReducer;
  const [sector, setSector] = useState<ISector>();

  // GET SECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    }
  }, [sectors]);

  useEffect(() => {
    if (sectorID) {
      const found: ISector | undefined = sectors
        .find((c: ISector) => c._id === sectorID);

      if (!found) return;
      setSector(found);
      dispatch({
        type: 'THEME_SET_TONIC',
        payload: found.color,
      });
    }
  }, [changedAt, sectors]);

  return (
    <div className={styles.sector}>
      <div className={styles.sidebar}>
        <div className={styles.containerTitle}>
          <div className={styles.back} onClick={() => navigate('/visualization')}>
            <BsArrowLeft size={32} />
          </div>
          <h1>{sector?.name}</h1>
          {user.role === 'Admin' && (
            <NavLink to={`/sector-edit/${sectorID}`}>
              <button>Edit the luxury code</button>
            </NavLink>
          )}
        </div>
        <SectorNav
          editable={false}
          baseUrl={`/sectors/${sectorID}`}
          isSectionLoaded={!!sector}
        />
      </div>
      {isLoading.includes(SECTORS_GET) && (
        <Loader />
      )}
      <div
        id='containerScroll'
        className={styles.container}
      >
        <div
          className={styles.content}
        >
          {sector && (
            <Outlet
              context={{ sector }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Characteristic;
