/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Radar, RadarChart, PolarGrid, PolarRadiusAxis, ResponsiveContainer,
} from 'recharts';
import styles from './radar-vision.module.scss';

interface RadarVisionProps {
  data: any;
  radar: { dataKey: string, color: string }[];
  vision: 'present' | 'future'
}

const getPointCoords = (x: number, y: number, r: number, rad: number) => ({
  x: x + r * Math.cos(rad),
  y: y + r * Math.sin(rad),
});

const center = {
  x: 280,
  y: 280,
};

const radius = 219;

const RadarVision = ({ data, radar, vision } : RadarVisionProps) => {
  const [sectionsPresent, setSectionsPresent] = useState<any>({});
  const [sectionsFuture, setSectionsFuture] = useState<any>({});

  useEffect(() => {
    if (data.length === 0) return;
    const shapesPresent: any = {};
    const shapesFuture: any = {};

    const count = data.length - 4;

    const getIndex = (key: string) => {
      let index = 0;
      if (key.includes('A')) index = 0;
      if (key.includes('B')) index = 1;
      if (key.includes('C')) index = 2;
      if (key.includes('D')) index = 3;
      return index;
    };

    data.forEach((radarItem: any, index: number) => {
      const { name } = radarItem;
      Object.keys(radarItem).filter((d) => d !== 'name' && /present/.test(d)).forEach((key: string) => {
        const offset = getIndex(key);
        const deg = ((360 / count) * (index - offset)) - 90;
        const rad = deg * (Math.PI / 180);
        const coords = getPointCoords(center.x, center.y, (radius / 10) * radarItem[key], rad);
        if (radarItem[key] && shapesPresent[key]) {
          shapesPresent[key].push({ name, ...coords });
        } else if (radarItem[key]) {
          shapesPresent[key] = [{ name, ...coords }];
        }
      });
      Object.keys(radarItem).filter((d) => d !== 'name' && /future/.test(d)).forEach((key: string) => {
        const offset = getIndex(key);
        const deg = ((360 / count) * (index - offset)) - 90;
        const rad = deg * (Math.PI / 180);
        const coords = getPointCoords(center.x, center.y, (radius / 10) * radarItem[key], rad);
        if (radarItem[key] && shapesFuture[key]) {
          shapesFuture[key].push({ name, ...coords });
        } else if (radarItem[key]) {
          shapesFuture[key] = [{ name, ...coords }];
        }
      });
    });
    setSectionsPresent(shapesPresent);
    setSectionsFuture(shapesFuture);
  }, [data]);

  const handleToggleTooltip = (e: any, show: Boolean = true) => {
    const tooltip = e.target.id.replace('dot', 'tooltip');
    const eltTooltip = document.getElementById(tooltip);
    if (show) eltTooltip?.classList.add(styles.active);
    else eltTooltip?.classList.remove(styles.active);
  };

  const getColor = useCallback((key: string) => {
    if (radar.length === 0) return '#ABC89B';
    const find = radar.find((d: any) => d.dataKey === key);
    return find ? find.color : '#ABC89B';
  }, [radar]);

  const getShape = (shape: any[], key:string) => {
    let points = `${center.x}, ${center.y}`;
    shape.forEach((d: any) => {
      points += ` ${d.x},${d.y}`;
    });
    const opacity = key.includes(vision) ? 0.7 : 0.3;
    return (
      <polygon
        key={`polygon-${vision}-${key}`}
        points={points}
        fill={getColor(key)}
        fillOpacity={opacity}
        stroke={getColor(key)}
      />
    );
  };

  return (
    <div className={styles.radar} style={{ width: '560px', height: '560px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={new Array((data?.length || 4) - 4).fill(null)}>
          <PolarGrid />
          <PolarRadiusAxis
            angle={90}
            tick={true}
            tickCount={10}
            domain={[0, 10]}
            label=''
          />
          <Radar
            name={'radar'}
            dataKey={'A'}
            // fillOpacity={0}
            // strokeOpacity={0}
          />
          {/* SHAPE  */}
          { vision === 'present' && (
            <>
              {Object.keys(sectionsFuture).map((key: string) => (
                <React.Fragment key={`${key}-futur-polygon`}>
                  {getShape(sectionsFuture[key], key)}
                </React.Fragment>
              ))}
              {Object.keys(sectionsPresent).map((key: string) => (
                <React.Fragment key={`${key}-present-polygon`}>
                  {getShape(sectionsPresent[key], key)}
                </React.Fragment>
              ))}
            </>
          )}
          { vision === 'future' && (
            <>
              {Object.keys(sectionsPresent).map((key: string) => (
                <React.Fragment key={`${key}-present-polygon`}>
                  {getShape(sectionsPresent[key], key)}
                </React.Fragment>
              ))}
              {Object.keys(sectionsFuture).map((key: string) => (
                <React.Fragment key={`${key}-futur-polygon`}>
                  {getShape(sectionsFuture[key], key)}
                </React.Fragment>
              ))}
            </>
          )}
          {/* DOTS AND TOOLTIPS */}
          { vision === 'present' && (
            <>
              {Object.keys(sectionsFuture).map((key: string) => (
                  <React.Fragment key={`${key}-futur-container`}>
                    {sectionsFuture?.[key].length > 0 && (
                      <>
                        {sectionsFuture[key].map((d: any, index:number) => (
                          <React.Fragment key={`${key}-futur-${index}`}>
                          <circle
                            cx={d.x}
                            cy={d.y}
                            r={5} fill={getColor(key)}
                            id={`dot-future-${key}-${index}`}
                            onMouseEnter={(e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e)}
                            onMouseLeave={
                            (e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e, false)}
                            />
                            <foreignObject
                              x={d.x - ((d.name.length * 8) / 2)}
                              y={d.y - 40}
                              width="100%"
                              height="100%"
                            >
                              <div id={`tooltip-future-${key}-${index}`} className={styles.tooltip}>
                                <p>{d.name}</p>
                              </div>
                            </foreignObject>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </React.Fragment>
              ))}
              {Object.keys(sectionsPresent).map((key: string) => (
                <React.Fragment key={`${key}-present-container`}>
                  {sectionsPresent?.[key].length > 0 && (
                    <>
                      {sectionsPresent[key].map((d: any, index:number) => (
                        <React.Fragment key={`${key}-present-${index}`}>
                          <circle
                            key={`${key}-present-${index}`}
                            cx={d.x}
                            cy={d.y}
                            r={5}
                            fill={getColor(key)}
                            id={`dot-present-${key}-${index}`}
                            onMouseEnter={(e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e)}
                            onMouseLeave={
                            (e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e, false)}
                          />
                          <foreignObject
                            x={d.x - ((d.name.length * 8) / 2)}
                            y={d.y - 40}
                            width="100%"
                            height="100%"
                          >
                            <div id={`tooltip-present-${key}-${index}`} className={styles.tooltip}>
                              <p>{d.name}</p>
                            </div>
                          </foreignObject>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          { vision === 'future' && (
            <>
              {Object.keys(sectionsPresent).map((key: string) => (
                <React.Fragment key={`${key}-present-container`}>
                  {sectionsPresent?.[key].length > 0 && (
                    <>
                      {sectionsPresent[key].map((d: any, index:number) => (
                        <React.Fragment key={`${key}-present-${index}`}>
                          <circle
                            key={`${key}-present-${index}`}
                            cx={d.x}
                            cy={d.y}
                            r={5}
                            fill={getColor(key)}
                            id={`dot-present-${key}-${index}`}
                            onMouseEnter={(e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e)}
                            onMouseLeave={
                            (e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e, false)}
                          />
                          <foreignObject
                            x={d.x - ((d.name.length * 8) / 2)}
                            y={d.y - 40}
                            width="100%"
                            height="100%"
                          >
                            <div id={`tooltip-present-${key}-${index}`} className={styles.tooltip}>
                              <p>{d.name}</p>
                            </div>
                          </foreignObject>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
              {Object.keys(sectionsFuture).map((key: string) => (
                  <React.Fragment key={`${key}-futur-container`}>
                    {sectionsFuture?.[key].length > 0 && (
                      <>
                        {sectionsFuture[key].map((d: any, index:number) => (
                          <React.Fragment key={`${key}-futur-${index}`}>
                          <circle
                            cx={d.x}
                            cy={d.y}
                            r={5} fill={getColor(key)}
                            id={`dot-future-${key}-${index}`}
                            onMouseEnter={(e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e)}
                            onMouseLeave={
                            (e: React.MouseEvent<SVGElement>) => handleToggleTooltip(e, false)}
                            />
                            <foreignObject
                              x={d.x - ((d.name.length * 8) / 2)}
                              y={d.y - 40}
                              width="100%"
                              height="100%"
                            >
                              <div id={`tooltip-future-${key}-${index}`} className={styles.tooltip}>
                                <p>{d.name}</p>
                              </div>
                            </foreignObject>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </React.Fragment>
              ))}
            </>
          )}
          {/* <Legend /> */}
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RadarVision;
