import React, { useMemo, CSSProperties } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Control, useFieldArray, useFormContext, useForm,
} from 'react-hook-form';
import { GrFormClose } from 'react-icons/gr';
import { RiAddLine } from 'react-icons/ri';
import { InputText, StyledSelect } from '../../../../lib/HooksFormFields';
import FieldMoodboard from '../../../../components/FieldMoodboard';
import iconWine from '../../../../assets/images/wine.svg';
import styles from './moodboard.module.scss';

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const SignalsCommunicated = (
  {
    control,
    options,
    tonic = 'transparent',
  } : {
    control: Control,
    options: { value: string }[],
    tonic?: string,
  },
) => {
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'signalsCommunicated',
    control,
  });

  const { control: controlSelect, getValues, reset } = useForm();

  const filteredOptions = useMemo(() => {
    const values = fields.map((field: any) => field.value);
    return (options || []).filter((option) => !values.includes(option.value));
  }, [options, fields]);

  return (
    <div className={styles.signalsCommunicated}>
      <label>Signals communicated</label>
      <div className={styles.selectedOptions} style={{ '--tonic': tonic } as customCSS}>
        {fields.map((field: any, index) => (
          <div className={styles.containerField} key={field.id}>
            <p>{field.value}</p>
            <div
              className={styles.remove}
              onClick={() => remove(index)}
            >
              <RiAddLine />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.containerSearch}>
        <StyledSelect
          name="signalCommunicated"
          control={controlSelect}
          isSearchable
          options={filteredOptions
            .map((option) => ({ value: option.value, label: option.value }))}
        />
        <button
          className={styles.append}
          onClick={() => {
            const option = getValues('signalCommunicated');
            console.log(option);
            if (!option) return;
            reset({ signalCommunicated: null });
            append({ value: option });
          }}
        >
          <RiAddLine />
        </button>
      </div>
    </div>
  );
};

const Moodboard = () => {
  const { submit, list, tonic } = useOutletContext<any>();
  const {
    control,
    getValues,
  } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'semioticSocials',
    control,
  });

  return (
    <div className={styles.moodboard}>
        <div>
          <FieldMoodboard
            label="Moodboard"
            name="moodboard"
            control={control}
            submitFile={(obj) => {
              const data = {
                ...getValues(),
                moodboard: obj.moodboard.filter((d: any) => d?.picture),
              };
              submit(data);
            }}
          />
        </div>
        <SignalsCommunicated
          options={list.signalsCommunicated}
          control={control}
          tonic={tonic}
        />
        <div className={styles.semioticSocials}>
          <label>
            <img src={iconWine} alt='connection to wine & spirits' />
            Connection to wine & spirits
          </label>
          {fields.map((field, index) => (
            <div className={styles.containerField} key={field.id}>
              <InputText
                name={`semioticSocials.${index}.value`}
                control={control}
              />
              <div
                className={styles.remove}
                onClick={() => remove(index)}
              >
                <GrFormClose />
              </div>
            </div>
          ))}
          <button
            className={styles.append}
            onClick={() => append({ value: '' })}
          >
            <span>Ajouter une ligne</span>
            <div className={styles.icon}>
              <RiAddLine />
            </div>
          </button>
        </div>
    </div>
  );
};

export default Moodboard;
