import React, { useEffect, useId, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Introduction from '../sections/introduction';
import Moodboard from '../sections/moodboard';
import Examples from '../sections/examples';
import View from '../sections/view';
import Links from '../sections/links';
import styles from './container-scroll.module.scss';

const ContainerScroll = () => {
  const introductionId = useId();
  const moodboardId = useId();
  const moodboardRef = useRef<any>();
  const examplesId = useId();
  const viewId = useId();
  const linksId = useId();
  const { pathname } = useLocation();
  useEffect(() => {
    let target = document.getElementById(introductionId);
    if (/moodboard/.test(pathname)) {
      target = document.getElementById(moodboardId);
    } else if (/examples/.test(pathname)) {
      target = document.getElementById(examplesId);
    } else if (/consumer-connect/.test(pathname)) {
      target = document.getElementById(viewId);
    } else if (/related-luxury-codes/.test(pathname)) {
      target = document.getElementById(linksId);
    }

    if (!target) return;

    const scrollOffsetY = (target?.offsetTop || 0) - 30;
    target.parentElement?.parentElement?.scrollTo(0, scrollOffsetY);
  }, [pathname]);

  return (
    <>
      <section data-section='introduction' id={introductionId} className={styles.section}>
        <Introduction />
      </section>
      <section data-section='moodboard' ref={moodboardRef} id={moodboardId} className={styles.section}>
        <Moodboard />
      </section>
      <section data-section='examples' id={examplesId} className={styles.section}>
        <Examples />
      </section>
      <section data-section='consumer-connect' id={viewId} className={styles.section}>
        <View />
      </section>
      <section data-section='related-luxury-codes' id={linksId} className={styles.section}>
        <Links />
      </section>
    </>
  );
};

export default ContainerScroll;
