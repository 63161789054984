import React, { useCallback } from 'react';
import {
  Control, FieldValues, useController, useFieldArray,
} from 'react-hook-form';
import { RiAddLine } from 'react-icons/ri';

import {
  InputMedia,
} from '../../lib/HooksFormFields';

import styles from './field-moodboard.module.scss';
import { set, get } from '../../utils';

interface FieldMoodboardProps {
  name: string,
  control: Control<any>,
  label?: string | null,
  handleChange?: (name: string, value: any) => void,
  submitFile: (data: FieldValues) => void,
}

const FieldMoodboard = ({
  name, control, label, submitFile,
}: FieldMoodboardProps) => {
  const {
    field: { value },
  } = useController({
    control,
    name,
  });

  const { fields, append } = useFieldArray({
    control,
    name,
    rules: { maxLength: 6 },
  });
  const handleSubmit = (obj: any, fieldName: string, fieldValue: string | null) => {
    const data = set({ [name]: fields }, fieldName, fieldValue);
    submitFile(data as FieldValues);
  };

  const handleDelete = useCallback((index: number) => {
    const data = JSON.parse(JSON.stringify(value.map((file: any) => file?._id || null)));
    data.splice(index, 1);
    submitFile({ [name]: data });
  }, [value]);

  const getValue = useCallback((path: string) => {
    const valueMedia = get({ [name]: value }, path);
    return valueMedia || null;
  }, [value]);

  return (
    <div className={styles.moodboard}>
      {label && <label>{label}</label>}
      <button
        className={`${styles.append}${fields.length === 6 ? ` ${styles.disabled}` : ''}`}
        onClick={() => {
          if (fields.length < 6) append({ picture: null });
        }}
        >
          <span>Add image</span>
          <div className={styles.icon}>
          <RiAddLine />
          </div>
        </button>
      {fields.length > 0 && (
        <div className={`${styles.grid} ${styles[`grid${fields.length}`]}`}>
          {fields.map((field, index) => (
            <InputMedia
              key={field.id}
              name={`${name}.${index}.picture`}
              value={getValue(`${name}.${index}.picture`)}
              withLink
              handleChange={(val) => {
                if (!val) handleDelete(index);
              }}
              submit={(obj, fieldName, fieldValue) => handleSubmit(obj, fieldName, fieldValue)}
            />
          ))}
        </div>
      )}

    </div>
  );
};

export default FieldMoodboard;
