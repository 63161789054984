import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  InputText, StyledSelect, Textarea, InputMedia, ErrorField,
} from '../../../../lib/HooksFormFields';
import styles from './description.module.scss';
import ISector from '../../../../types/sector';

const Description = () => {
  const { sectors } = useSelector((state: any) => state.sectorsReducer);
  const { characteristic, submit } = useOutletContext<any>();
  const {
    formState: { errors },
    watch,
    getValues,
    control,
  } = useFormContext();

  return (
    <div className={styles.description}>
      <div className={styles.containerField}>
        <InputText
          name="name"
          placeholder='Characteristic name...'
          control={control}
          rules={{
            required: 'Please enter a characteristic name',
          }}
        />
        {errors?.name && <ErrorField message={errors?.name?.message} />}
      </div>
      <div className={styles.details}>
        <div className={styles.sectorAndDesc}>
          <div className={styles.containerField}>
            <StyledSelect
              name="sector"
              placeholder='Sector...'
              control={control}
              className='bold'
              options={sectors.map((sector: ISector) => (
                { label: sector.name, value: sector._id }
              ))}
              rules={{
                required: 'Please select a sector',
              }}
            />
            {errors?.sector && <ErrorField message={errors?.sector?.message} />}
          </div>
          <div className={styles.containerField}>
            <Textarea
              name="description"
              placeholder='Description...'
              control={control}
              maxlength={400}
              rules={{
                required: 'Please enter a form description',
              }}
            />
            {errors?.description && <ErrorField message={errors?.description?.message} />}
          </div>
        </div>
        <div className={styles.picture}>
          <div className={styles.containerField}>
            <InputMedia
              name='picture'
              value={watch('picture')}
              disabled={!characteristic}
              btnText='Choose picture'
              submit={(obj: FieldValues) => {
                submit({
                  ...getValues(),
                  ...obj,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
