import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AiOutlineDelete } from 'react-icons/ai';
import { deleteCharacteristic } from '../../actions/characteristics';
import withModalConfirm from '../../lib/withModalConfirm';
import styles from './characteristic-nav.module.scss';

const CharacteristicNav = ({
  baseUrl,
  editable = true,
  characteristicID = null,
  isCharacteristicLoad = false,
  confirm,
} : {
  baseUrl: string,
  editable?: boolean,
  characteristicID: string | null,
  isCharacteristicLoad?: boolean,
  confirm: any,
}) => {
  const [section, setSection] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // HANDLE HIGHLIGHT SECTION
  useEffect(() => {
    // const handleRouteChange = () => setSection(null);
    const container = document.getElementById('containerScroll');
    if (!container || !isCharacteristicLoad || editable) return undefined;
    const sections = container.querySelectorAll('section');

    function handleScroll() {
      let i = 0;
      const offset = 120;
      while (sections[i]?.getBoundingClientRect().bottom < offset) {
        i += 1;
      }
      if (i >= 0 && sections[i].dataset.section) setSection(sections[i].dataset.section || null);
      else { setSection(null); }
    }

    handleScroll();
    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isCharacteristicLoad]);

  const isActive = useCallback((target: string) => {
    let className = '';
    if (editable && (location.pathname === `${baseUrl}/${target}`)) {
      className = styles.active;
    } else if (!editable && section === target) {
      className = styles.active;
    }
    return className;
  }, [location.pathname, section]);

  const handleDelete = useCallback(() => {
    confirm(
      'Are you sure you want to delete this characteristic ?',
      async () => {
        const response = await deleteCharacteristic(dispatch, {
          _id: characteristicID,
        });
        if (response.status === 200) navigate('/visualization', { replace: true });
      },
    );
  }, [characteristicID]);

  return (
    <nav className={styles.nav}>
      {editable && <NavLink
        className={() => (
          (location.pathname === `${baseUrl}/description` || location.pathname === baseUrl) ? styles.active : '')
        }
        to={`${baseUrl}/description`}
      >
        Description
      </NavLink>}
      <NavLink
        className={() => isActive('introduction')}
        to={`${baseUrl}/introduction`}
      >
        Introduction
      </NavLink>
      <NavLink
        className={() => isActive('moodboard')}
        to={`${baseUrl}/moodboard`}
      >
        Connection to wine & spirits
      </NavLink>
      <NavLink
        className={() => isActive('examples')}
        to={`${baseUrl}/examples`}
      >
        Examples
      </NavLink>
      <NavLink
        className={() => isActive('consumer-connect')}
        to={`${baseUrl}/consumer-connect`}
      >
        Consumer connect
      </NavLink>
      {!editable && (
        <NavLink
          className={() => isActive('related-luxury-codes')}
          to={`${baseUrl}/related-luxury-codes`}
        >
          Related luxury codes
        </NavLink>
      )}
      {editable && <div className={styles.delete}>
        <button onClick={handleDelete}>
          <AiOutlineDelete size={20} />
          <span>Delete characteristic</span>
        </button>
      </div>}
    </nav>
  );
};

export default withModalConfirm(CharacteristicNav);
