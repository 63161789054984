import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './sector-nav.module.scss';

const SectorNav = (
  {
    baseUrl,
    editable = true,
    isSectionLoaded = false,
  } : {
    baseUrl: string,
    editable?: boolean,
    isSectionLoaded?: boolean,
  },
) => {
  const location = useLocation();
  const [section, setSection] = useState<string | null>(null);

  // HANDLE HIGHLIGHT SECTION
  useEffect(() => {
    // const handleRouteChange = () => setSection(null);
    const container = document.getElementById('containerScroll');
    if (!container || !isSectionLoaded || editable) return undefined;
    const sections = container.querySelectorAll('section');

    function handleScroll() {
      let i = 0;
      const offset = 120;
      while (sections[i]?.getBoundingClientRect().bottom < offset) {
        i += 1;
      }
      if (i >= 0 && sections[i].dataset.section) setSection(sections[i].dataset.section || null);
      else { setSection(null); }
    }

    handleScroll();
    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isSectionLoaded]);

  return (
    <nav className={styles.nav}>
    {editable ? (
      <>
      <NavLink
        className={() => (
          (location.pathname === `${baseUrl}/description` || location.pathname === baseUrl) ? styles.active : '')
        }
        to={`${baseUrl}/description`}
      >
        Description
      </NavLink>
      <NavLink
          className={() => (
            (location.pathname === `${baseUrl}/introduction`) ? styles.active : '')
          }
          to={`${baseUrl}/introduction`}
        >
          Introduction
        </NavLink>
      <NavLink
        className={() => (
          (location.pathname === `${baseUrl}/moodboard`) ? styles.active : '')
        }
        to={`${baseUrl}/moodboard`}
      >
        Consumer Typologies
      </NavLink>
      </>
    ) : (
      <>
        <NavLink
          className={section === 'introduction' ? styles.active : ''}
          to={`${baseUrl}/introduction`}
        >
          Introduction
        </NavLink>
        <NavLink
          className={section === 'moodboard' ? styles.active : ''}
          to={`${baseUrl}/moodboard`}
        >
          Consumer Typologies
        </NavLink>
      </>
    )}

    <NavLink
      className={section === 'country-revelance' ? styles.active : ''}
      to={`${baseUrl}/country-revelance`}
    >
      Country revelance
    </NavLink>
  </nav>
  );
};

export default SectorNav;
