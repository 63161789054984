import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFieldArray, useFormContext, FieldValues } from 'react-hook-form';
import { RiAddLine } from 'react-icons/ri';
import { ImLink } from 'react-icons/im';
import { InputMedia, InputText, Textarea } from '../../../../lib/HooksFormFields';
import { set } from '../../../../utils';

import styles from './examples.module.scss';

const Examples = () => {
  const { characteristic, submit } = useOutletContext<any>();
  const {
    control,
    getValues,
    watch,
  } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'examples',
    control,
  });
  return (
      <div className={styles.examples}>
        <button
        className={styles.append}
        onClick={() => append({ value: '' })}
        >
          <span>Add example</span>
          <div className={styles.icon}>
          <RiAddLine />
        </div>
        </button>
        <div className={styles.gridExamples}>
          {fields.map((field, index) => (
            <div key={field.id}>
              <div className={styles.containerField} >
                <InputMedia
                  name={`examples.${index}.picture`}
                  value={watch(`examples.${index}.picture`)}
                  disabled={!characteristic}
                  btnText='Choose picture'
                  className={watch(`examples.${index}.picture`)
                    ? styles.thumbnail : `${styles.thumbnail} ${styles.hidden}`
                  }
                  submit={(obj: FieldValues, name, value) => {
                    const data = set(
                      {
                        examples: getValues('examples'),
                      },
                      name,
                      value,
                    );
                    submit(data);
                  }}
                />
              </div>
              <div className={`${styles.containerField} ${styles.title}`} >
                <InputText
                    name={`examples.${index}.title`}
                    control={control}
                />
              </div>
              <div className={`${styles.containerField} ${styles.description}`} >
                <Textarea
                  name={`examples.${index}.description`}
                  control={control}
                />
              </div>
              <div className={`${styles.containerField} ${styles.url}`} >
                <ImLink />
                <span>https://</span>
                <InputText
                  name={`examples.${index}.url`}
                  control={control}
                />
              </div>
              <div
                className={styles.remove}
              >
                <p onClick={() => remove(index)}>Delete</p>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default Examples;
