import React, { useMemo } from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import { ICharacteristic } from '../../../../types/characteristics';
import styles from './links.module.scss';

// related-luxury-codes

const RelatedLuxuryCodes = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { sector, characteristic } = useOutletContext<any>();

  const links = useMemo(() => sector.characteristics
    .filter((c: ICharacteristic) => c._id !== characteristic._id), [sector, characteristic]);

  return (
    <div
      className={styles.links}
    >
      <div className={styles.container}>
        <div className={styles.links}>
          {links.map((link: ICharacteristic) => (
            <NavLink to={`/characteristics/${link._id}`} className={styles.linkedCharacteristic} key={link._id}>
              <div className={styles.containerImage}>
                { link.picture && (
                  <img
                    alt={link?.picture.alt}
                    src={`${process.env.REACT_APP_API_URL}/files/public/${link.picture.path}?token=${token}`}
                  />
                )}
              </div>
              <p>
                {link.name}
              </p>
            </NavLink>
          ))}
        </div>
      </div>

    </div>
  );
};

export default RelatedLuxuryCodes;
