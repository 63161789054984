import {
  CHARACTERISTIC_POST,
  CHARACTERISTIC_PUT,
  CHARACTERISTIC_LOADING,
  CHARACTERISTIC_ERROR,
} from '../actions/types';
import { ActionReducer } from '../types';

interface ICharacteristicState {
  isLoading: string[],
  error: string | null,
}

const DEFAULT_STATE: ICharacteristicState = {
  isLoading: [],
  error: null,
};

const characteristicsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  switch (action.type) {
    case CHARACTERISTIC_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case CHARACTERISTIC_PUT:
    case CHARACTERISTIC_POST:
      updatedState = {
        ...state,
        isLoading: state.isLoading?.filter(
          (d) => d !== `${action.type}-${action.payload.characteristic._id}`,
        ),
        error: null,
      };
      break;
    case CHARACTERISTIC_ERROR:
      // eslint-disable-next-line no-case-declarations
      const message = action?.payload?.data?.error;
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default characteristicsReducer;
