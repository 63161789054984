const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

const set = (obj: Record<string, any> = {}, path: string | string[], value: any) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  if (!pathArray) return null;
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
  return obj;
};

const hexToRGB = (hex: string) => {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);

  // return {r, g, b}
  return { red, green, blue };
};

const getLuminance = (r: number, g: number, b: number) => {
  const [lumR, lumG, lumB] = [r, g, b].map((value) => {
    const sRGB = value / 255;

    return sRGB <= 0.03928 ? sRGB / 12.92 : ((sRGB + 0.055) / 1.055) ** 2.4;
  });

  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
};

export {
  get,
  set,
  hexToRGB,
  getLuminance,
};
