import React from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  useController, useFieldArray, useFormContext, FieldValues,
} from 'react-hook-form';
import { FlagIcon } from 'react-flag-kit';
import { GrFormClose } from 'react-icons/gr';
import { RiAddLine } from 'react-icons/ri';
import {
  InputNumber, InputText, Textarea, InputMedia,
} from '../../../../lib/HooksFormFields';
import styles from './introduction.module.scss';

const Introduction = () => {
  const { characteristic, submit } = useOutletContext<any>();

  const {
    control,
    watch,
    getValues,
  } = useFormContext();

  // RANK
  const {
    field: { onChange: handleChangeRank, value: rank },
  } = useController({
    name: 'rank',
    control,
  });

  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'socioCulturals',
    control,
  });

  return (
    <div className={styles.introduction}>
      <label>Presentation illustration</label>
      <div className={`${styles.containerField} ${styles.cover}`}>
        <InputMedia
          name='cover'
          value={watch('cover')}
          disabled={!characteristic}
          btnText='Choose cover'
          submit={(obj: FieldValues) => {
            submit({
              ...getValues(),
              ...obj,
            });
          }}
        />
      </div>
      <div className={styles.distribution}>
        <div className={styles.selectRank}>
            <label>
              {rank === 3 && 'Dominant'}
              {rank === 2 && 'Growing'}
              {rank === 1 && 'Emerging'}
            </label>
            <ul>
                <li>
                  <div
                    className={`${styles.rank} ${styles.rank3}${rank === 3 ? ` ${styles.selected}` : ''}`}
                    onClick={() => handleChangeRank(3)}
                  />
                </li>
                <li>
                  <div
                    className={`${styles.rank} ${styles.rank2}${rank === 2 ? ` ${styles.selected}` : ''}`}
                    onClick={() => handleChangeRank(2)}
                  />
                </li>
                <li>
                  <div
                    className={`${styles.rank} ${styles.rank1}${rank === 1 ? ` ${styles.selected}` : ''}`}
                    onClick={() => handleChangeRank(1)}
                  />
                </li>
            </ul>
        </div>
        <div className={styles.percents}>
          <div className={styles.containerInput}>
            <div className={styles.location}>
              <FlagIcon code="FR" />
              <p>France</p>
            </div>
            <InputNumber
              unit="%"
              name="percents.fr"
              control={control}
            />
          </div>
          <div className={styles.containerInput}>
            <div className={styles.location}>
              <FlagIcon code="US" />
              <p>USA</p>
            </div>
            <InputNumber
              unit="%"
              name="percents.us"
              control={control}
            />
          </div>
          <div className={styles.containerInput}>
            <div className={styles.location}>
              <FlagIcon code="CN" />
              <p>CHINA</p>
            </div>
            <InputNumber
              unit="%"
              name="percents.cn"
              control={control}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.containerField} ${styles.introduction}`}>
        <Textarea
          name="introduction"
          control={control}
          rows={4}
        />
      </div>
      <div className={styles.twoColumns}>
        <div className={`${styles.containerField} ${styles.video}`}>
          <InputMedia
            name='video'
            type='video'
            value={watch('video')}
            disabled={!characteristic}
            btnText='Choose video'
            submit={(obj: FieldValues) => {
              submit({
                ...getValues(),
                ...obj,
              });
            }}
          />
        </div>
        <div className={styles.socioCulturals}>
          <label>Socio-cultural Elements</label>
          {fields.map((field, index) => (
            <div className={styles.containerField} key={field.id}>
              <InputText
                name={`socioCulturals.${index}.value`}
                control={control}
              />
              <div
                className={styles.remove}
                onClick={() => remove(index)}
              >
                <GrFormClose />
              </div>
            </div>
          ))}
          <button
            className={styles.append}
            onClick={() => append({ value: '' })}
          >
            <span>Ajouter une ligne</span>
            <div className={styles.icon}>
              <RiAddLine />
            </div>
          </button>
        </div>
      </div>
      <div className={`${styles.fromTo}`}>
        <InputText
          name="from"
          control={control}
        />
        <div className={styles.arrow} />
        <InputText
          name="to"
          control={control}
        />
      </div>
    </div>
  );
};

export default Introduction;
