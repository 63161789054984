import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { FieldValues, useFormContext } from 'react-hook-form';
import {
  InputText, Textarea, InputMedia, ErrorField,
} from '../../../../lib/HooksFormFields';
import styles from './description.module.scss';

const Description = () => {
  const { sector, submit } = useOutletContext<any>();
  const {
    formState: { errors },
    watch,
    getValues,
    control,
  } = useFormContext();

  return (
    <div className={styles.description}>
      <div className={styles.containerField}>
        <InputText
          name="name"
          placeholder='Luxury code name...'
          control={control}
          rules={{
            required: 'Please enter a luxury code name',
          }}
        />
        {errors?.name && <ErrorField message={errors?.name?.message} />}
      </div>
      <div className={styles.details}>
        <div className={styles.sectorAndBaseline}>
            <div className={styles.containerField}>
            <InputText
              name="baseline"
              placeholder='...'
              control={control}
              rules={{
                required: 'Please enter a baseline',
              }}
            />
            {errors?.name && <ErrorField message={errors?.name?.message} />}
          </div>
          <div className={styles.containerField}>
            <Textarea
              name="description"
              placeholder='Description...'
              control={control}
              maxlength={400}
              rules={{
                required: 'Please enter a form description',
              }}
            />
            {errors?.description && <ErrorField message={errors?.description?.message} />}
          </div>
        </div>
        <div className={styles.picture}>
          <div className={styles.containerField}>
            <InputMedia
              name='thumbnail'
              value={watch('thumbnail')}
              disabled={!sector}
              btnText='Choose thumbnail'
              submit={(obj: FieldValues) => {
                submit({
                  ...getValues(),
                  ...obj,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
