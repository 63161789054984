import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../actions/auth';
import loginImage from '../../assets/images/login.png';
import logo from '../../assets/images/logo.svg';
import ResetPwdForm from '../../components/form/AuthForm/ResetPwdForm';
import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const [succeed, setSucceed] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/club');
  }, [authReducer.user]);

  function submitReset(data: FieldValues) {
    resetPassword(dispatch, {
      password: data.password,
      token: params?.token || '',
    }).then((res: any) => {
      if (res?.status === 200) setSucceed(true);
    });
  }

  return (
    <div className={styles['reset-password']}>
      <div className={styles.container}>
        <div>
          <h1>Mot de passe oublié</h1>
          <ResetPwdForm
            submit={submitReset}
            signinLink={succeed ? '/' : null}
            succeedMessage={
              succeed ? 'Votre mot de passe a été mis à jour.' : null
            }
            errorMessage={authReducer.error}
          />
        </div>
          <div className={styles.containerImage}>
          <img className={styles.logo} src={logo} alt="logo" />
          <img src={loginImage} alt="login" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
