import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import quiz from '../../../assets/images/quiz.png';
import { getProfileAction } from '../../../actions/auth';
import putQuiz from '../../../actions/user';
import styles from './start.module.scss';

const PageQuiz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleStartQuiz = async () => {
    await putQuiz({});
    await getProfileAction(dispatch);
    navigate('/brand-assessment/1');
  };

  return (
    <div className={styles.quizStart}>
      <div>
        <h1>Brand assessment</h1>
        <p>
          Based on your review of the codes, some might be more pertinent
          at present, while others could become more important in the
          future. Please rank them for relevancy, using 1 as the least
          relevant and 10 as the most. This will help us obtain a clearer
          assessment of your brand and/or activation.
        </p>
        <button onClick={() => handleStartQuiz()}>
          Start
        </button>
      </div>
      <div>
        <img src={quiz} alt="quiz" />
      </div>
    </div>
  );
};

export default PageQuiz;
