import React, {
  useState, useEffect, useMemo,
} from 'react';
import { motion } from 'framer-motion';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import { ICharacteristicExtended } from '../../../types/characteristics';
import RadarVision from '../../../components/RadarVision';
// import Chart from '../../../components/Chart';
import ScatterChart from '../../../components/ScatterChart';
import AnimatedText from '../../../components/AnimatedText';
import styles from './vision.module.scss';
import ISector from '../../../types/sector';

const downloadPDF = async (filename: string) => {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
    },
  };
  const url = `${process.env.REACT_APP_API_URL}/users/export-quiz`;

  try {
    const response = await fetch(url, config);
    if (response.status !== 200) {
      throw new Error();
    }
    const blob = await response.blob();
    const urlObject = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    console.log(e);
  }
};

const variants = {
  hidden: {
    y: '140%',
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.6,
    },
  },
  visible: {
    y: [100, 0],
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.4,
    },
  },
  out: {
    y: 0,
    opacity: 0,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.3,
    },
  },
};

const sectorsKey: any = {
  '64b694ae2b23cae71388d16b': 'A', // EXPLO
  '64b7f74bbf050d3d67a5a450': 'B', // DISPLAY
  '64b7f762bf050d3d67a5a454': 'C', // DISCERNING,
  '64b7f773bf050d3d67a5a458': 'D', // SELF IMPROV,
};

const PageVision = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const navigate = useNavigate();
  const {
    characteristics, sectors, user,
    // indexes,
  } = useOutletContext<any>();
  const [radarData, setRadarData] = useState([]);
  const [replay, setReplay] = useState(true);
  const [vision, setVision] = useState<'present' | 'future'>('present');
  const [view, setView] = useState<'radar' | 'matrix'>('matrix');
  const [radar, setRadar] = useState<{ dataKey: string, color: string }[]>([]);
  const [PDFLoading, setPDFLoading] = useState(false);

  useEffect(() => {
    const isCompleted = characteristics
      .every((c: ICharacteristicExtended) => c.present !== null && c.future !== null);
    if (!isCompleted) navigate('/brand-assessment/start');
  }, []);

  // INIT RADAR
  useEffect(() => {
    if (sectors.length === 0) return;
    const radarSections: { dataKey: string, color: string }[] = [];
    sectors.forEach((sector: ISector) => {
      radarSections.push({
        dataKey: `${sectorsKey[sector._id]}-present`,
        color: sector.color,
      });
      radarSections.push({
        dataKey: `${sectorsKey[sector._id]}-future`,
        color: sector.color,
      });
    });
    setRadar(radarSections);
  }, [sectors]);

  // INIT RADAR DATA
  useEffect(() => {
    const data: any = [];

    characteristics.forEach((characteristic: ICharacteristicExtended) => {
      const obj: any = {
        name: characteristic.name,
        'A-present': 0,
        'A-future': 0,
        'B-present': 0,
        'B-future': 0,
        'C-present': 0,
        'C-future': 0,
        'D-present': 0,
        'D-future': 0,
      };

      const sectorId: string = characteristic.sector._id || '';
      const presentKey: string = `${sectorsKey[sectorId]}-present`;
      const futureKey: string = `${sectorsKey[sectorId]}-future`;
      obj[presentKey] = characteristic.present;
      obj[futureKey] = characteristic.future;

      data.push(obj);
      // if (presentKey === 'A-present' || futureKey === 'C-future') data.push(obj);
    });
    setRadarData(data);
  }, [characteristics]);

  const toggleVision = (nextVision: 'present' | 'future') => {
    setReplay(false);
    setTimeout(() => {
      setVision(nextVision);
      setReplay(true);
    }, 500);
  };

  const top4Characteristics = useMemo(() => {
    const sortedCharacteristics = JSON
      .parse(JSON.stringify(characteristics)).sort((a: any, b: any) => {
        if (a[vision] !== b[vision]) return a[vision] > b[vision] ? -1 : 1;
        return 0;
      });
    return sortedCharacteristics.slice(0, 4);
  }, [vision, characteristics]);

  return (
    <div className={styles.quizVision}>
      <div className={styles.navigation}>
        <div>
          <ul className={styles.toggleChart}>
            <li
                className={view === 'radar' ? styles.active : ''}
                onClick={() => setView('radar')}
              >
                Radar
              </li>
              <li
                className={view === 'matrix' ? styles.active : ''}
                onClick={() => setView('matrix')}
            >
              Matrix
            </li>
          </ul>
        </div>
        <div className={styles.radar}>
          {view === 'matrix' && characteristics.length > 0 ? (
            <div>
              <>{console.log(characteristics)}</>
              <ScatterChart data={characteristics} />
            </div>
          ) : (
            <RadarVision
              data={radarData}
              radar={radar}
              vision={vision}
            />
          )}
        </div>
      </div>
      <div className={styles.characteristics}>
        <div>
          <ul className={styles.toggleVision}>
            <li
                className={vision === 'present' ? styles.active : ''}
                onClick={() => toggleVision('present')}
              >
                Present
              </li>
              <li
                className={vision === 'future' ? styles.active : ''}
                onClick={() => toggleVision('future')}
            >
              Future
            </li>
          </ul>
          <button
            className={PDFLoading ? styles.loading : ''}
            onClick={async () => {
              setPDFLoading(true);
              await downloadPDF(
                `${user.profile.lastName.toLowerCase()}-${user.profile.firstName.toLowerCase()}-${new Date().getTime()}.pdf`,
              );
              setPDFLoading(false);
            }}
          >
            Download
          </button>
        </div>
        {top4Characteristics.length > 0 && top4Characteristics
          .map((characteristic: any, index: number) => (
          <div
            key={`characteristic-${index}`} className={styles.characteristic}
          >
            <div className={styles.containerImg} >
              <motion.div
                key={`img-${vision}-${characteristic?.picture?._id}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  bounce: 0,
                }}
                className={styles.img}
              >
                <div
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URL}/files/public/${characteristic?.picture?.path}?token=${token})`,
                  }}
                />
              </motion.div>
            </div>
            <motion.div
              initial="hidden"
              animate={replay ? 'visible' : 'out'}
            >
              <AnimatedText variants={variants} type={'heading3'} text={characteristic.name} />
              <div className={styles.containerBar}>
                <motion.div
                  className={styles.bar}
                  key={`${vision}-bar-${characteristic._id}`}
                  initial={{
                    width: `${(100 / 10)
                  * (characteristic[vision === 'present' ? 'future' : 'present'] || 0)}%`,
                  }}
                  animate={{
                    width: `${(100 / 10) * (characteristic[vision] || 0)}%`,
                    backgroundColor: characteristic.sector.color,
                  }}
                />
              </div>
              <NavLink to={`/characteristics/${characteristic._id}`} style={{
                position: 'relative',
                display: 'inline-block',
                height: '37px',
                overflow: 'hidden',
              }}>
                <button className='light' >
                  See more +
                </button>
              </NavLink>
            </motion.div>
          </div>
          ))}
      </div>
    </div>
  );
};

// RADAR AVEC 10 note y
// chaque segment et un caractertistique
// avec une couche transparent pour la vision disabled

export default PageVision;
