import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormContext, FieldValues } from 'react-hook-form';
import { Textarea, InputMedia, InputText } from '../../../../lib/HooksFormFields';
import styles from './introduction.module.scss';

const Introduction = () => {
  const { sector, submit } = useOutletContext<any>();
  const { watch, control, getValues } = useFormContext();
  return (
    <div className={styles.introduction}>
      <div className={styles.picture}>
        <div className={`${styles.containerField} ${styles.picture}`}>
          <InputMedia
            name='picture'
            value={watch('picture')}
            disabled={!sector}
            btnText='Choose picture'
            submit={(obj: FieldValues) => {
              submit({
                ...getValues(),
                ...obj,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.containerField}>
        <label>SOCIAL SIGNALLING IS</label>
        <Textarea
          name="socialSignals"
          control={control}
          maxlength={200}
          rows={4}
        />
      </div>
      <div className={styles.containerField}>
        <label>Art de vivre is</label>
        <Textarea
          name="artOfLiving"
          control={control}
          maxlength={200}
          rows={4}
        />
      </div>
      <label>Key shif in this space</label>
      <div className={`${styles.fromTo}`}>
        <InputText
          name="from"
          control={control}
        />
        <div className={styles.arrow} />
        <InputText
          name="to"
          control={control}
        />
      </div>
    </div>
  );
};

export default Introduction;
