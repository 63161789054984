import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFieldArray, useFormContext, FieldValues } from 'react-hook-form';
import { GrFormClose } from 'react-icons/gr';
import { RiAddLine } from 'react-icons/ri';
import { set } from '../../../../utils';
import { InputText, Textarea, InputMedia } from '../../../../lib/HooksFormFields';
import FieldMoodboard from '../../../../components/FieldMoodboard';
import styles from './moodboard.module.scss';

const Moodboard = () => {
  const { sector, submit } = useOutletContext<any>();

  const {
    getValues,
    watch,
    control,
  } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'keyTakeOuts',
    control,
  });

  return (
    <div className={styles.moodboard}>
        <div>
          <FieldMoodboard
            label="Moodboard"
            name="moodboard"
            control={control}
            submitFile={(obj) => {
              const data = {
                ...(getValues()),
                moodboard: obj.moodboard.filter((d: any) => d?.picture),
              };
              submit(data);
            }}
          />
        </div>
        <div className={styles.signalsCommunicated}>
            <label>Key take outs</label>
            {fields.map((field, index) => (
                <div className={styles.containerField} key={field.id}>
                <InputText
                    name={`keyTakeOuts.${index}.value`}
                    control={control}
                />
                <div
                    className={styles.remove}
                    onClick={() => remove(index)}
                >
                    <GrFormClose />
                </div>
                </div>
            ))}
            <button
                className={styles.append}
                onClick={() => append({ value: '' })}
            >
                <span>Ajouter une ligne</span>
                <div className={styles.icon}>
                  <RiAddLine />
                </div>
            </button>
        </div>
        <label>Main consumer typologies</label>
        <div className={styles.gridTwo}>
          <div>
            <label>Primary</label>
            <div className={styles.containerField}>
              <InputMedia
                name='consumerTypologies.primary.picture'
                value={watch('consumerTypologies.primary.picture')}
                disabled={!sector}
                btnText='Choose picture'
                submit={(obj: FieldValues, name, value) => {
                  const data = set(
                    { consumerTypologies: getValues('consumerTypologies'), ...(getValues()) },
                    name,
                    value,
                  );
                  submit(data);
                }}
              />
            </div>
            <div className={styles.containerField}>
                <InputText
                    name="consumerTypologies.primary.title"
                    placeholder='Title...'
                    control={control}
                />
            </div>
            <div className={styles.containerField}>
                <Textarea
                    name="consumerTypologies.primary.signaling"
                    placeholder='Signaling...'
                    control={control}
                    rows={5}
                    maxlength={144}
                />
            </div>
          </div>
          <div>
            <label>Secondary</label>
            <div className={styles.containerField}>
              <InputMedia
                name='consumerTypologies.secondary.picture'
                value={watch('consumerTypologies.secondary.picture')}
                disabled={!sector}
                btnText='Choose picture'
                submit={(obj: FieldValues, name, value) => {
                  const data = set(
                    { consumerTypologies: getValues('consumerTypologies'), ...(getValues()) },
                    name,
                    value,
                  );
                  submit(data);
                }}
              />
            </div>
            <div className={styles.containerField}>
              <InputText
                  name="consumerTypologies.secondary.title"
                  placeholder='Title...'
                  control={control}
              />
            </div>
            <div className={styles.containerField}>
              <Textarea
                  name="consumerTypologies.secondary.signaling"
                  placeholder='Signaling...'
                  control={control}
                  rows={5}
                  maxlength={144}
              />
            </div>
          </div>
        </div>
    </div>
  );
};

export default Moodboard;
