import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { SECTORS_GET } from '../../actions/types';
import { getSectors } from '../../actions/sectors';
import styles from './dataviz.module.scss';
import Chart from '../../components/Chart';
import AnimatedText from '../../components/AnimatedText';
import { ICharacteristic } from '../../types/characteristics';
import { IFile } from '../../types/files';
import ISector from '../../types/sector';

interface INode extends ICharacteristic {
  category: number,
  sector: string,
  radius: number,
  opacity: number
}

interface IPreview {
  title: string,
  rank?: number,
  description: string,
  image?: IFile,
  to?: string,
  default?: boolean,
}

const defaultPreview = {
  title: 'Visualization',
  default: true,
  description: 'Social signaling is expressed through consumer choices, reflecting deeply ingrained personal, social, and cultural values that evolve over time. Luxury brands once used price and scarcity for social signaling. Now, they must also portray a caring image, balancing inclusivity and exclusivity, collective good and individual desire, and depth and display.',
};

const indexes: Record<string, number> = {
  '64b7f773bf050d3d67a5a458': 0,
  '64b694ae2b23cae71388d16b': 1,
  '64b7f74bbf050d3d67a5a450': 2,
  '64b7f762bf050d3d67a5a454': 3,
};

const variants = {
  hidden: {
    y: '140%',
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.6,
    },
  },
  visible: {
    y: [100, 0],
    opacity: 1,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.4,
    },
  },
  out: {
    y: 0,
    opacity: 0,
    transition: {
      // ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.3,
    },
  },
};

const Dataviz = () => {
  const token = localStorage.getItem('token');

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const sectorsReducer = useSelector((d: any) => d.sectorsReducer);
  const { sectors, isLoading } = sectorsReducer;
  const [characteristics, setCharacteristics] = useState<INode[]>([]);
  const [replay, setReplay] = useState(true);
  const [preview, setPreview] = useState<IPreview>(defaultPreview);

  // GET SECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    } else if (sectors.length > 0 && !isLoading.includes(SECTORS_GET)) {
      const nodes: INode[] = [];
      sectors.forEach((sector: ISector) => {
        sector.characteristics.forEach((characteristic: ICharacteristic) => {
          nodes.push({
            ...characteristic,
            category: indexes[sector._id] || 0,
            sector: sector._id,
            radius: (characteristic.rank || 1) * 7,
            opacity: 1,
          });
        });
      });
      setCharacteristics(nodes);
    }
  }, [sectors]);

  const unselectElement = () => {
    const params = new URLSearchParams();
    setSearchParams(params);
  };

  // HANDLE SET PREVIEW
  useEffect(() => {
    if (!sectors.length) return;
    const characteristic = searchParams.get('characteristic');
    const sector = searchParams.get('sector');
    if (characteristic) {
      const found: INode | undefined = characteristics.find((c) => c._id === characteristic);
      if (!found) return;
      setReplay(!replay);
      setTimeout(() => {
        setReplay(true);
        setPreview({
          title: found?.name,
          rank: found?.rank,
          description: found.description,
          to: `/characteristics/${found._id}`,
          image: found?.picture,
        });
      }, 450);
      const foundSector: ISector | undefined = sectors.find((c: ISector) => c._id === found.sector);
      if (!foundSector) return;
      dispatch({
        type: 'THEME_SET_TONIC',
        payload: foundSector.color,
      });
    } else if (sector) {
      const found: ISector | undefined = sectors.find((c: ISector) => c._id === sector);
      if (!found) return;
      setReplay(!replay);
      setTimeout(() => {
        setReplay(true);
        setPreview({
          title: found?.name,
          description: found.description,
          to: `/sectors/${found._id}`,
          image: found?.thumbnail,
        });
      }, 450);
      dispatch({
        type: 'THEME_SET_TONIC',
        payload: found.color,
      });
    } else {
      setPreview(defaultPreview);
    }
  }, [sectors, characteristics, searchParams]);

  const trimJWT = useMemo(() => (token ? token.replace('JWT ', '') : null), [token]);
  const sectorsName = useMemo(() => {
    const names = new Array(sectors.length).fill(null);
    Object.entries(indexes).forEach(([key, value] : [string, number]) => {
      const found = sectors.find((c: ISector) => c._id === key);
      names[value] = found?.name;
    });
    return names;
  }, [sectors]);

  return (
    <div className={styles.dataviz}>
      {characteristics.length > 0 && (
        <>
          <Chart sectors={sectorsName} data={characteristics} />
          <div className={styles.container}>
            <motion.div
              initial="hidden"
              animate={replay ? 'visible' : 'out'}
            >
            {(preview && !preview.default) && (<div
              className={styles.close}
              onClick={() => unselectElement()}
            >
              <motion.div variants={variants} />
            </div>)}
              <AnimatedText variants={variants} type={'heading1'} text={preview.title} />
              {preview.rank && (
                <div
                  className={styles.selectRank}
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    height: '32px',
                    overflow: 'hidden',
                  }}
                >
                  <motion.ul variants={variants}>
                      <li>
                        <div
                          className={`${styles.rank} ${styles.rank3}${preview.rank === 3 ? ` ${styles.selected}` : ''}`}
                        />
                      </li>
                      <li>
                        <div
                          className={`${styles.rank} ${styles.rank2}${preview.rank === 2 ? ` ${styles.selected}` : ''}`}
                        />
                      </li>
                      <li>
                        <div
                          className={`${styles.rank} ${styles.rank1}${preview.rank === 1 ? ` ${styles.selected}` : ''}`}
                        />
                      </li>
                  </motion.ul>
                  <label>
                    {preview.rank === 3 && 'Dominant'}
                    {preview.rank === 2 && 'Growing'}
                    {preview.rank === 1 && 'Emerging'}
                  </label>
                </div>
              )}
              <AnimatedText variants={variants} type={'paragraph'} text={preview.description} />
              {preview.to && (
                <NavLink to={preview.to} style={{
                  position: 'relative',
                  display: 'inline-block',
                  height: '37px',
                  overflow: 'hidden',
                }}>
                  <motion.button
                    variants={variants}
                    className='light'
                  >
                    See more +
                  </motion.button>
                </NavLink>
              )}
              {preview?.image && (
                <div className={styles.image}>
                  <motion.img
                    variants={variants}
                    src={`${process.env.REACT_APP_API_URL}/files/public/${preview.image.path}?token=${trimJWT}`}
                    alt={preview.image.alt}
                  />
                </div>
              )}
            </motion.div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dataviz;
