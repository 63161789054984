import React from 'react';

const DataFrame = ({ sectors, animated = true } : { sectors: string[], animated: Boolean }) => {
  const configAnimation = {
    axisX: { delay: !animated ? '0s' : '0.5s', dur: '0.5s' },
    axisY: { delay: !animated ? '0s' : '1s', dur: '0.5s' },
    arc: { delay: !animated ? '0s' : '1.5s', dur: '0.6s' },
    sectionName: { delay: !animated ? '0s' : '2s', dur: '0.5s' },
    sectionGradient: { delay: !animated ? '0s' : '2s', dur: '0.5s' },
    text: { delay: !animated ? '0s' : '2.5s', dur: '0.5s' },
    tipArrowX: { delay: !animated ? '0s' : '0.8s', dur: '0.2s' },
    tipArrowY: { delay: !animated ? '0s' : '2.3s', dur: '0.2s' },
  };

  return (
    <svg width="869" height="671" viewBox="0 0 869 671" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* <circle
        id="pathSectors"
        cx="435"
        cy="334.700"
        r="305"
        fill="#F2F2F2"
        stroke="black"
        strokeWidth="0.978159"
    /> */}
    <g id="22">
      <g id="frame">
        <text
          id="EXPLORE"
          fill="black"
          xmlSpace="preserve"
          fontFamily="Century Gothic"
          fontSize="12"
          letterSpacing="0.13em"
          opacity={animated ? '0' : '1'}
        >
          <tspan x="406.498" y="12.2139">EXPLORE</tspan>
          <animate
            attributeName="opacity"
            dur={configAnimation.text.dur}
            fill="freeze"
            from="0"
            to="1"
            repeatCount="1"
            begin={configAnimation.text.delay}
          />
        </text>
        <text
          id="EXTE"
          fill="black"
          xmlSpace="preserve"
          fontFamily="Century Gothic"
          fontSize="12"
          letterSpacing="0.13em"
          opacity={animated ? '0' : '1'}
        >
          <tspan x="775" y="339.214">EXTERNAL</tspan>
          <animate
            attributeName="opacity"
            dur={configAnimation.text.dur}
            fill="freeze"
            from="0"
            to="1"
            repeatCount="1"
            begin={configAnimation.text.delay}
          />
        </text>
        <text
          id="INTE"
          fill="black"
          xmlSpace="preserve"
          fontFamily="Century Gothic"
          fontSize="12"
          letterSpacing="0.13em"
          opacity={animated ? '0' : '1'}
        >
          <tspan x="32" y="339.214">INTERNAL</tspan>
          <animate
            attributeName="opacity"
            dur={configAnimation.text.dur}
            fill="freeze"
            from="0"
            to="1"
            repeatCount="1"
            begin={configAnimation.text.delay}
          />
        </text>
        <text
          id="CONFORM"
          fill="black"
          xmlSpace="preserve"
          fontFamily="Century Gothic"
          fontSize="12"
          letterSpacing="0.13em"
          opacity={animated ? '0' : '1'}
        >
          <tspan x="402.947" y="668.214">CONFORM</tspan>
          <animate
            attributeName="opacity"
            dur={configAnimation.text.dur}
            fill="freeze"
            from="0"
            to="1"
            repeatCount="1"
            begin={configAnimation.text.delay}
          />
        </text>
        <g id="axes">
          <path
            id="line-top"
            d="M435.197 23.7477V327.298"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            strokeDashoffset={animated ? '-40%' : '0%'}
            strokeDasharray="40% 40%"
          >
            {animated && <animate
              attributeName="stroke-dashoffset"
              dur={configAnimation.axisY.dur}
              fill="freeze"
              from="-40%"
              to="0%"
              repeatCount="1"
              begin={configAnimation.axisY.delay}
            />}
          </path>
          <path
            id="top"
            d="M431.773 26.851L435.197 23.4764L438.304 26.7782"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            opacity={animated ? '0' : '1'}
          >
            <animate
              attributeName="opacity"
              dur={configAnimation.tipArrowY.dur}
              fill="freeze"
              from="0"
              to="1"
              repeatCount="1"
              begin={configAnimation.tipArrowY.delay}
            />
          </path>
          <path
            id="line-bottom"
            d="M434.88 645.259V341.702"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            strokeDashoffset={animated ? '-40%' : '0%'}
            strokeDasharray="40% 40%"
          >
            {animated && <animate
              attributeName="stroke-dashoffset"
              dur={configAnimation.axisY.dur}
              fill="freeze"
              from="-40%"
              to="0%"
              repeatCount="1"
              begin={configAnimation.axisY.delay}
            />}
          </path>
          <path
            id="bottom"
            d="M438.304 642.149L434.88 645.53L431.773 642.222"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            opacity={animated ? '0' : '1'}
          >
            <animate
              attributeName="opacity"
              dur={configAnimation.tipArrowY.dur}
              fill="freeze"
              from="0"
              to="1"
              repeatCount="1"
              begin={configAnimation.tipArrowY.delay}
            />
          </path>
          <path
            id="line-left"
            d="M106.476 334.854H428.468"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            strokeDashoffset={animated ? '-50%' : '0%'}
            strokeDasharray="50% 50%"
          >
            {animated && <animate
              attributeName="stroke-dashoffset"
              dur={configAnimation.axisX.dur}
              fill="freeze"
              from="-50%"
              to="0%"
              repeatCount="1"
              begin={configAnimation.axisX.delay}
            />}
          </path>
          <path
            id="left"
            d="M109.846 338.282L106.476 334.854L109.774 331.744"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            opacity={animated ? '0' : '1'}
          >
            <animate
              attributeName="opacity"
              dur={configAnimation.tipArrowX.dur}
              fill="freeze"
              from="0"
              to="1"
              repeatCount="1"
              begin={configAnimation.tipArrowX.delay}
            />
          </path>
          <path
            id="line-right"
            d="M765.525 335.013H443.525"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            strokeDashoffset={animated ? '-50%' : '0%'}
            strokeDasharray="50% 50%"
          >
            {animated && <animate
              attributeName="stroke-dashoffset"
              dur={configAnimation.axisX.dur}
              fill="freeze"
              from="-50%"
              to="0%"
              repeatCount="1"
              begin={configAnimation.axisX.delay}
            />}
          </path>
          <path
            id="right"
            d="M762.153 331.585L765.524 335.013L762.226 338.116"
            stroke="#3C3C3B"
            strokeMiterlimit="10"
            opacity={animated ? '0' : '1'}
          >
            <animate
              attributeName="opacity"
              dur={configAnimation.tipArrowX.dur}
              fill="freeze"
              from="0"
              to="1"
              repeatCount="1"
              begin={configAnimation.tipArrowX.delay}
            />
          </path>
        </g>
        <g id="circle">
          <g id="exploration">
            <path
              id="Vector 12"
              opacity={animated ? '0' : '0.3'}
              d="M537.324 60.6373L456.137 43.5195H439.019V331.587H727.087L716.816 255.291L677.69 168.724L612.642 101.231L537.324 60.6373Z"
              fill="url(#paint0_linear_0_1)"
            >
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionGradient.dur}
                fill="freeze"
                from="0"
                to="0.3"
                repeatCount="1"
                begin={configAnimation.sectionGradient.delay}
              />
            </path>
            <path
              id="Ellipse30"
              d="M725.62 331.587C725.62 173.032 597.086 44.4977 438.53 44.4977"
              stroke="#6EA052"
              strokeWidth="3.91264"
              strokeDashoffset={animated ? '58%' : '0%'}
              strokeDasharray="58% 58%"
            >
              {animated && <animate
                attributeName="stroke-dashoffset"
                dur={configAnimation.arc.dur}
                fill="freeze"
                from="58%"
                to="0%"
                repeatCount="1"
                begin={configAnimation.arc.delay}
              />}
            </path>
            <path id="PathExploration" d="M439 35C535.333 36.3333 729.8 98 737 334" />
            <g
              id="exploration-text"
              opacity={animated ? '0' : '1'}
            >
              <text
                fill="#7CBB5A"
                fontFamily="Century Gothic"
                fontSize="12"
                textAnchor="center"
                letterSpacing="0.45em"
              >
                <textPath startOffset="50%" textAnchor="middle" href="#PathExploration">{sectors[1].toUpperCase()}</textPath>
              </text>
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionName.dur}
                fill="freeze"
                from="0"
                to="1"
                repeatCount="1"
                begin={configAnimation.sectionName.delay}
              />
            </g>
          </g>
          <g id="self_improvement">
            <path
            id="Vector 11"
            opacity={animated ? '0' : '0.3'}
            d="M319.195 67.9735L363.701 52.812L432.172 43.5195L430.216 331.587H143.615L159.266 237.684L200.348 159.92L257.571 103.187L319.195 67.9735Z"
            fill="url(#paint1_linear_0_1)"
            >
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionGradient.dur}
                fill="freeze"
                from="0"
                to="0.3"
                repeatCount="1"
                begin={configAnimation.sectionGradient.delay}
              />
            </path>
            <path
              id="Ellipse 29"
              d="M145.083 331.587C145.083 173.032 273.617 44.4977 432.173 44.4977"
              stroke="#9784BB"
              strokeWidth="3.91264"
              strokeDashoffset={animated ? '-58%' : '0'}
              strokeDasharray="58% 58%"
            >
              {animated && <animate
                attributeName="stroke-dashoffset"
                dur={configAnimation.arc.dur}
                fill="freeze"
                from="-58%"
                to="0%"
                repeatCount="1"
                begin={configAnimation.arc.delay}
              />}
            </path>
            <path id="pathSelfImprovement" d="M130 329C129 235.667 187.2 46.2 428 35"/>
            <g id="Group_2" opacity={animated ? '0' : '1'}>
              <text
                fill="#8066B0"
                fontFamily="Century Gothic"
                fontSize="12"
                textAnchor="center"
                letterSpacing="0.45em"
              >
                <textPath startOffset="50%" textAnchor="middle" href="#pathSelfImprovement">{sectors[0].toUpperCase()}</textPath>
              </text>
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionName.dur}
                fill="freeze"
                from="0"
                to="1"
                repeatCount="1"
                begin={configAnimation.sectionName.delay}
              />
            </g>
          </g>
          <g id="display">
            <path
              id="Vector 13"
              opacity={animated ? '0' : '0.3'}
              d="M557.866 600.092L484.015 621.612L438.531 623.079L439.998 339.902L726.599 337.946L720.241 404.949L683.071 492.006L624.87 558.031L557.866 600.092Z"
              fill="url(#paint2_linear_0_1)"
            >
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionGradient.dur}
                fill="freeze"
                from="0"
                to="0.3"
                repeatCount="1"
                begin={configAnimation.sectionGradient.delay}
              />
            </path>
            <path
              id="Ellipse 32"
              d="M725.62 337.946C725.62 496.501 597.086 625.035 438.53 625.035"
              stroke="#EBB057"
              strokeWidth="3.91264"
              strokeDashoffset={animated ? '-58%' : '0%'}
              strokeDasharray="58% 58%"
            >
              {animated && <animate
                attributeName="stroke-dashoffset"
                dur={configAnimation.arc.dur}
                fill="freeze"
                from="-58%"
                to="0%"
                repeatCount="1"
                begin={configAnimation.arc.delay}
              />}
            </path>
            <path id="pathDisplay" d="M436 647C541 642.333 751.6 573.8 754 337"/>
            <g id="Group_3" opacity={animated ? '0' : '1'}>
              <text
                fill="#DEAC5B"
                fontFamily="Century Gothic"
                fontSize="12"
                textAnchor="center"
                letterSpacing="0.45em"
              >
                <textPath startOffset="53%" textAnchor="middle" href="#pathDisplay">{sectors[2].toUpperCase()}</textPath>
              </text>
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionName.dur}
                fill="freeze"
                from="0"
                to="1"
                repeatCount="1"
                begin={configAnimation.sectionName.delay}
              />
            </g>
          </g>
          <g id="discerning">
            <path
              id="Vector 14"
              opacity={animated ? '0' : '0.3'}
              d="M346.583 613.786L418.967 625.524L432.172 623.568V337.945H144.104L156.821 425.98L199.86 509.123L264.418 573.193L346.583 613.786Z"
              fill="url(#paint3_linear_0_1)"
            >
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionGradient.dur}
                fill="freeze"
                from="0"
                to="0.3"
                repeatCount="1"
                begin={configAnimation.sectionGradient.delay}
              />
            </path>
            <path
              id="Ellipse 31"
              d="M145.083 337.945C145.083 496.501 273.617 625.035 432.173 625.035"
              stroke="#3157A4"
              strokeWidth="3.91264"
              strokeDashoffset={animated ? '58%' : '0'}
              strokeDasharray="58% 58%"
            >
              {animated && <animate
                attributeName="stroke-dashoffset"
                dur={configAnimation.arc.dur}
                fill="freeze"
                from="58%"
                to="0%"
                repeatCount="1"
                begin={configAnimation.arc.delay}
              />}
            </path>
            <path id="pathDiscerning" d="M126.136 338C122.803 438.5 178.5 631.5 430.5 640" />
            <g id="Group_4" opacity={animated ? '0' : '1'}>
              <text
                fill="#2B4271"
                fontFamily="Century Gothic"
                fontSize="12"
                textAnchor="center"
                letterSpacing="0.45em"
              >
                <textPath startOffset="50%" textAnchor="middle" href="#pathDiscerning">{sectors[3].toUpperCase()}</textPath>
              </text>
              <animate
                attributeName="opacity"
                dur={configAnimation.sectionName.dur}
                fill="freeze"
                from="0"
                to="1"
                repeatCount="1"
                begin={configAnimation.sectionName.delay}
              />
            </g>
          </g>
        </g>
      </g>
      <circle
        id="Ellipse 33"
        cx="435.596"
        cy="334.989"
        r="7.33619"
        fill="#F2F2F2"
        stroke="black"
        strokeWidth="0.978159"
        opacity={animated ? '0' : '1'}
      >
        {animated && <animate
          attributeName="opacity"
          dur='0.3s'
          fill="freeze"
          from="0"
          to="1"
          repeatCount="1"
          begin='0.2s'
        />}
      </circle>
    </g>
    <defs>
    <linearGradient id="paint0_linear_0_1" x1="632.205" y1="115.903" x2="500.154" y2="263.605" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6EA052"/>
    <stop offset="1" stopColor="#6EA052" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_0_1" x1="237.029" y1="115.903" x2="369.081" y2="263.605" gradientUnits="userSpaceOnUse">
    <stop stopColor="#9784BB"/>
    <stop offset="1" stopColor="#9784BB" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_0_1" x1="633.184" y1="555.586" x2="501.133" y2="407.884" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EBB057"/>
    <stop offset="1" stopColor="#EBB057" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint3_linear_0_1" x1="238.986" y1="553.629" x2="371.037" y2="405.927" gradientUnits="userSpaceOnUse">
    <stop stopColor="#465D8B"/>
    <stop offset="1" stopColor="#465D8B" stopOpacity="0"/>
    </linearGradient>
    </defs>
  </svg>
  );
};

export default DataFrame;
