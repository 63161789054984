import React, { useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FlagIcon } from 'react-flag-kit';
import styles from './introduction.module.scss';

const Introduction = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { characteristic } = useOutletContext<any>();
  const video = useRef<HTMLVideoElement>(null);
  return (
    <div className={styles.introduction}>
      {characteristic?.cover && (
        <div className={styles.cover}>
          <img
            src={`${process.env.REACT_APP_API_URL}/files/public/${characteristic.cover.path}?token=${token}`}
          />
        </div>
      )}
      <div className={styles.distribution}>
        <div className={styles.selectRank}>
            <label>
              {characteristic.rank === 3 && 'Dominant'}
              {characteristic.rank === 2 && 'Growing'}
              {characteristic.rank === 1 && 'Emerging'}
            </label>
            <ul>
                <li>
                  <div
                    className={`${styles.rank} ${styles.rank3}${characteristic.rank === 3 ? ` ${styles.selected}` : ''}`}
                  />
                </li>
                <li>
                  <div
                    className={`${styles.rank} ${styles.rank2}${characteristic.rank === 2 ? ` ${styles.selected}` : ''}`}
                  />
                </li>
                <li>
                  <div
                    className={`${styles.rank} ${styles.rank1}${characteristic.rank === 1 ? ` ${styles.selected}` : ''}`}
                  />
                </li>
            </ul>
        </div>
        <div className={styles.percents}>
          <div className={styles.containerPercent}>
            <div className={styles.location}>
              <FlagIcon code="FR" />
              <p>France</p>
            </div>
            <div
              className={styles.percent}
              style={{
                height: `${characteristic.percents.fr ? characteristic.percents.fr : 0}px`,
              }}
            />
          </div>
          <div className={styles.containerPercent}>
            <div className={styles.location}>
              <FlagIcon code="US" />
              <p>USA</p>
            </div>
            <div
              className={styles.percent}
              style={{
                height: `${characteristic.percents.us ? characteristic.percents.us : 0}px`,
              }}
            />
          </div>
          <div className={styles.containerPercent}>
            <div className={styles.location}>
              <FlagIcon code="CN" />
              <p>CHINA</p>
            </div>
            <div
              className={styles.percent}
              style={{
                height: `${characteristic.percents.cn ? characteristic.percents.cn : 0}px`,
              }}
            />
          </div>
        </div>
      </div>
      <p className={styles.introduction}>{characteristic.introduction}</p>
      <div className={styles.twoColumns}>
        {characteristic.video && (
          <div className={styles.containerVideo}>
            <video ref={video} className={styles.video} controls>
              <source src={`${process.env.REACT_APP_API_URL}/files/public/${characteristic.video.path}?token=${token}`} />
            </video>
          </div>
        )}
        {characteristic.socioCulturals.length > 0 && (
          <div className={styles.socioCulturals}>
            <h5>Socio-cultural elements</h5>
            {characteristic.socioCulturals.map((d: { value: string, _id: string }) => (
              <div className={styles.item} key={`socioCultural-${d._id}`}>
                <p>{d.value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {(characteristic.to && characteristic.from) && (
        <>
          <div className={`${styles.fromTo}`}>
            <p>{characteristic.from}</p>
            <div className={styles.arrow} />
            <p>{characteristic.to}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Introduction;
