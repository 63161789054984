import React, {
  useEffect, useState, useRef, useId, useMemo,
} from 'react';
import axios from 'axios';
import { FieldValues, useForm } from 'react-hook-form';
import { RiDeleteBin2Fill, RiCloseLine, RiSave2Fill } from 'react-icons/ri';
import { FaPenNib } from 'react-icons/fa';
import { ImLink } from 'react-icons/im';
// Contect & actions
import { useDispatch } from 'react-redux';
import { deleteFile, updateFile } from '../../../actions/file';

// Styles
import styles from './media.module.scss';
import InputText from '../InputText';

interface IFile {
  _id: string,
  fileName: string,
  originalname: string,
  name: string,
  path: string,
  alt: string,
  mimetype: string,
}

const mimetypes = {
  image: 'image/png, image/jpeg',
  video: 'video/*',
};

const set = (obj: Record<string, any> = {}, path: string | string[], value: any) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  if (!pathArray) return null;
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
  return obj;
};

function InputMedia({
  disabled = false,
  value,
  label,
  name,
  type = 'image',
  btnText = 'Choisir un fichier',
  className = null,
  withLink = false,
  // handleChange,
  submit,
} : {
  index?: number | null,
  name: string,
  type?: 'image' | 'video',
  btnText?: string,
  label?: string | null,
  value?: string,
  disabled?: boolean,
  withLink?: boolean,
  className?: string | null,
  handleChange?: (value: any) => void,
  submit: (value: any, key: string, fileId: string | null) => void,
}) {
  const token = localStorage.getItem('token');
  const inputId = useId();
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setFocus,
  } = useForm<FieldValues>();
  const inputRef = useRef<null>(null);
  const dispatch = useDispatch();
  const [isEditAlt, setIsEditAlt] = useState<boolean>(false);
  const [isEditUrl, setIsEditUrl] = useState<boolean>(false);
  const [media, setMedia] = useState<IFile | undefined | null>();

  useEffect(() => {
    if (media) {
      reset(media);
    }
  }, [media]);

  useEffect(() => {
    if (isEditAlt) {
      setFocus('alt');
    }
  }, [isEditAlt]);

  // FETCH FILE
  useEffect(() => {
    if (!value) return;
    const config: any = {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}/files/${value}`, config)
      .then((response : any) => {
        setMedia(response.data.file);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [value]);

  async function handleChangeFile(e : any) {
    const fileData = e.target.files[0];
    if (media?._id) {
      deleteFile(dispatch, media._id);
      submit({ [name]: null }, name, null);
    }
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('name', fileData.name);
    formData.append('alt', fileData.name.split('.')[0]);
    return new Promise((resolve, reject) => {
      const config: any = {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      axios.post(`${process.env.REACT_APP_API_URL}/files`, formData, config)
        .then((response : any) => {
          resolve(response.data);
          setMedia(response.data.file);
          submit({ [name]: response.data.file._id }, name, response.data.file._id);
        })
        .catch((error: any) => {
          reject(error);
          console.log(error);
        });
    });
  }

  function deleteCurrentFile() {
    if (media?._id) {
      deleteFile(dispatch, media._id);
      const data = set({}, name, null);
      submit(data, name, null);
      setMedia(null);
      // handleChange(null);
    }
  }

  function editmedia() {
    const values = getValues();
    setIsEditAlt(false);
    setIsEditUrl(false);
    updateFile(dispatch, values);
  }

  const trimJWT = useMemo(() => (token ? token.replace('JWT ', '') : null), [token]);

  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <div className={`${className ? `${className} ` : ''}${styles.img}${disabled ? ` ${styles.disabled}` : ''}`}>
        {(trimJWT && media?.path)
          ? <>
            {media.mimetype === 'video/mp4'
              ? <video controls>
                <source
                  src={`${process.env.REACT_APP_API_URL}/files/public/${media.path}?token=${trimJWT}`}
                  type={media.mimetype}>
                </source>
              </video>
              : (
                <img
                  src={`${process.env.REACT_APP_API_URL}/files/public/${media.path}?token=${trimJWT}`} alt={media.alt}
                />
              )
            }
            {(!isEditAlt && !isEditUrl)
              && <>
              <div className={styles['btn-container']}>
                {withLink && (
                <button
                  type="button"
                  onClick={() => setIsEditUrl(true)}
                >
                  <ImLink />
                </button>
                )}
                <button
                  type="button"
                  onClick={() => setIsEditAlt(!isEditAlt)}
                  >
                  <FaPenNib />
                </button>
                <button
                  type="button"
                  onClick={() => deleteCurrentFile()}
                  >
                  <RiDeleteBin2Fill />
                </button>
              </div>
              </>
            }
          </>
          : <div className={`${styles.input}${disabled ? ` ${styles.disabled}` : ''}`}>
              <label htmlFor={inputId}>{btnText}</label>
              <input
                disabled={disabled}
                id={inputId}
                type='file'
                accept={mimetypes[type]}
                onChange={(e) => handleChangeFile(e)}
                ref={inputRef}
              />
          </div>
        }
        {/* EDIT ALT */}
        {typeof media?.alt === 'string'
          && <div className={`${styles.edit} ${isEditAlt ? styles.active : ''}`}>
              <button
                type="button"
                onClick={() => setIsEditAlt(!isEditAlt)}
                className={styles.close}
              >
                <RiCloseLine size={30}/>
              </button>
            <div className={styles.form}>
              <label>alt</label>
              <div className={styles.field}>
                <InputText
                  name='alt'
                  control={control}
                />
                <button
                  type="button"
                  onClick={handleSubmit(editmedia)}
                  className={styles.submit}
                >
                  <RiSave2Fill size={26}/>
                </button>
              </div>
            </div>
          </div>
        }
        {/* EDIT URL */}
        { isEditUrl && (
          <div className={`${styles.edit} ${styles.active}`}>
            <button
              type="button"
              onClick={() => setIsEditUrl(false)}
              className={styles.close}
            >
              <RiCloseLine size={30}/>
            </button>
          <div className={styles.form}>
            <label>Link</label>
            <div className={styles.field}>
              <span className={styles.https}>https://</span>
              <InputText
                name='url'
                control={control}
              />
              <button
                type="button"
                onClick={handleSubmit(editmedia)}
                className={styles.submit}
                >
                <RiSave2Fill size={26}/>
              </button>
            </div>
          </div>
        </div>
        )}
      </div>

    </div>
  );
}

export default InputMedia;
