import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import styles from './country-revelance.module.scss';

interface IValue {
  _id: string;
  value: string;
}

const Country = (
  { name, title, fields = [] } : { name: string, title: string, fields: IValue[] },
) => (
    <div className={styles.country}>
      <div className={styles.containerFlag}>
        <div className={styles.flag}>
          <FlagIcon code={name.toUpperCase() as FlagIconCode} />
        </div>
      </div>
      <div className={styles.container}>
        <h4>{title}</h4>
        {fields.map((field: IValue) => (
          <div className={styles.containerField} key={field._id}>
            <p>{field.value}</p>
          </div>
        ))}
      </div>
    </div>
);

const CountryRevelance = () => {
  const { sector } = useOutletContext<any>();

  return (
    <div className={styles.countryRevelance}>
      <Country name="fr" title="France" fields={sector.fr} />
      {sector.us.length > 0 && <Country name="us" title="USA" fields={sector.us} />}
      {sector.cn.length > 0 && <Country name="cn" title="CHINA" fields={sector.cn} />}
    </div>
  );
};

export default CountryRevelance;
