import React, { useCallback, useEffect, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  motion, useSpring, animate, stagger,
} from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { setSector } from '../../actions/sectors';
import ISector from '../../types/sector';
import styles from './sector.module.scss';
import { ICharacteristic } from '../../types/characteristics';

interface ISectorProps {
  sector: ISector,
}

const animationConfig = { stiffness: 120, damping: 20 };

const Sector = ({ sector } : ISectorProps) => {
  const contentId = useId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sector: selectedSector } = useSelector((d: any) => d.sectorsReducer);
  const height = useSpring(0, animationConfig);
  const token = localStorage.getItem('token')?.replace('JWT ', '');

  useEffect(() => {
    if (!sector.characteristics.length) return;
    if (sector._id === selectedSector?._id) {
      const element = document.getElementById(contentId)?.childNodes[0] as HTMLDivElement;
      height.set(element?.clientHeight);
      animate(
        `#sector_${sector._id} .${styles.img}`,
        { y: 0 },
        { ...animationConfig, damping: 15, delay: stagger(0.2) },
      );
    } else {
      animate(
        `#sector_${sector._id} .${styles.img}`,
        { y: '100%' },
        { ...animationConfig, damping: 15, delay: stagger(0.2) },
      );
      setTimeout(() => {
        height.set(0);
      }, 400);
    }
  }, [sector.characteristics, selectedSector]);

  const handleSelectSector = useCallback(() => {
    setSector(dispatch, sector._id === selectedSector?._id ? null : sector);
    dispatch({
      type: 'THEME_SET_TONIC',
      payload: sector.color,
    });
  }, [selectedSector]);

  return (
    <div className={styles.sector} key={sector._id}>
        <div className={styles.title}>
          <div>
            <h1 onClick={() => handleSelectSector()}>{sector.name}</h1>
            <p>{sector.baseline}</p>
          </div>
          <motion.button
            className='light'
            onClick={() => navigate(`/sectors/${sector._id}`)}
            animate={{
              opacity: sector._id === selectedSector?._id ? 1 : 0,
              x: sector._id === selectedSector?._id ? 0 : -20,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ bounce: 0 }}
          >
            See more +
          </motion.button>
        </div>
        { sector.characteristics.length > 0 && (
          <motion.div id={contentId} style={{ height, overflow: 'hidden' }}>
            <div>
              <ul id={`sector_${sector._id}`}>
                { sector.characteristics.map((characteristic: ICharacteristic, i) => (
                  <li key={i} onClick={() => navigate(`/characteristics/${characteristic._id}`)}>
                    <div className={styles.containerImg} >
                      <div className={styles.img} style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}/files/public/${characteristic?.picture?.path}?token=${token})`,
                      }} />
                    </div>
                    <div className={styles.characteristicTitle}>
                      <h5>{characteristic.name}</h5>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
        <div className={styles.separator} />
    </div>
  );
};

export default Sector;
