import React from 'react';
import { useOutletContext } from 'react-router-dom';
import styles from './introduction.module.scss';

const Introduction = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { sector } = useOutletContext<any>();

  return (
    <div className={styles.introduction}>
      {sector.picture?.path && (
        <div className={styles.containerPicture}>
          <img
            alt={sector.picture.alt}
            src={`${process.env.REACT_APP_API_URL}/files/public/${sector.picture.path}?token=${token}`}
          />
        </div>
      )}
      <h3 className={styles.baseline}>{sector.baseline}</h3>
      {sector.socialSignals && (
        <>
          <h5>Social signalling is</h5>
          <p>{sector.socialSignals}</p>
        </>
      )}
      {sector.artOfLiving && (
        <>
          <h5>Art de vivre is</h5>
          <p>{sector.artOfLiving}</p>
        </>
      )}
      {(sector.to && sector.from) && (
        <>
          <h5>Key shif in this space</h5>
          <div className={`${styles.fromTo}`}>
            <p>{sector.from}</p>
            <div className={styles.arrow} />
            <p>{sector.to}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Introduction;
