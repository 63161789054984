import React, { ReactNode } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const Component = ({ children } : { children : ReactNode }) => (
  <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 550: 2, 750: 3 }}
  >
      <Masonry gutter="15px" >
        {children}
      </Masonry>
  </ResponsiveMasonry>
);

export default Component;
