const putQuiz = async (answers: any) => {
  let result = null;
  try {
    const config = {
      method: 'PUT',
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz: answers,
      }),
    };

    const url = `${process.env.REACT_APP_API_URL}/users/profile`;
    const response = await fetch(url, config);

    if (response.status === 200) {
      result = (await response.json())?.user?.quiz || {};
    }
  } catch (e) {
    console.log(e);
  }
  return result;
};

export default putQuiz;
