import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SECTORS_GET } from '../../actions/types';
import { getSectors } from '../../actions/sectors';
import ISector from '../../types/sector';
import styles from './luxury-codes.module.scss';
import Sector from '../../components/Sector';

const LuxuryCodes = () => {
  const dispatch = useDispatch();
  const { authReducer, sectorsReducer } = useSelector((d: any) => d);
  const { user } = authReducer;
  const { sectors, isLoading } = sectorsReducer;

  // GET VECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    }
  }, [sectors]);

  return (
    <div className={styles.luxuryCodes}>
        <div className={styles.content}>
            <div className={styles.banner}>
              <div className={styles.luxury}>
                <h3>Luxury Futures</h3>
                <p>Adapting to new social signals</p>
              </div>
              {user.role === 'Admin' && (
                <NavLink to="/characteristic-edit">
                  <button>New luxury code</button>
                </NavLink>
              )}
            </div>
            <div className={styles.sectors}>
              <div className={styles.separator} />
              {sectors.length > 0 && sectors.map((sector: ISector) => (
                <Sector key={sector._id} sector={sector} />
              ))}
            </div>
        </div>
    </div>
  );
};

export default LuxuryCodes;
