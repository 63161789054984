import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import themeReducer from './themeReducer';
import sectorsReducer from './sectorsReducer';
import characteristicsReducer from './characteristicsReducer';

const rootReducer = combineReducers({
  authReducer,
  themeReducer,
  sectorsReducer,
  characteristicsReducer,
});

const store = createStore(
  rootReducer,
);

export default store;
