import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import styles from './header.module.scss';

const Header = () => {
  const user = useSelector((d: any) => d.authReducer.user);
  return user ? (
      <div className={styles.header}>
        <div className={styles.content}>
          <img className={styles.logo} src={logo} alt="Moët Hennessy" />
          <ul>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='/luxury-codes'>
                Luxury codes
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='/visualization'>
                Visualization
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to='/brand-assessment'>
                Brand Assessment
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
  ) : null;
};

export default Header;
