import React, { useMemo, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { ImLink } from 'react-icons/im';
import { hexToRGB, getLuminance } from '../../../../utils';
import { IFile } from '../../../../types/files';
import iconWine from '../../../../assets/images/wine.svg';
import styles from './moodboard.module.scss';

interface customCSS extends CSSProperties {
  '--tonic': string;
}

// connection-to-wine-&-spirits

const Moodboard = () => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  const { characteristic } = useOutletContext<any>();
  const themeReducer = useSelector((d: any) => d.themeReducer);
  const { tonic } = themeReducer;

  const textColor = useMemo(() => {
    const rgb = hexToRGB(tonic);
    const luminance = getLuminance(rgb.red, rgb.green, rgb.blue);
    return luminance < 0.5 ? '#FFFFFF' : '#000000';
  }, [tonic]);

  const renderImage = (picture: IFile) => {
    let img = (
      <img
        alt={picture.alt}
        src={`${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${token}`}
      />
    );
    if (picture.url) {
      img = (
        <a href={`https://${picture.url}`}>
          {img}
          <div className={styles.icon}>
            <ImLink />
          </div>
        </a>
      );
    }
    return (
      <div key={`image-${picture._id}`}>
        {img}
      </div>
    );
  };

  return (
    <div className={styles.moodboard}>
      {characteristic.moodboard.length > 0 && (
        <div className={`${styles.moodboard} ${styles[`grid${characteristic.moodboard.length}`]}`}>
          {characteristic.moodboard.map((picture: IFile) => renderImage(picture))}
       </div>
      )}
      {characteristic.signalsCommunicated.length > 0 && (
        <div className={styles.signalsCommunicated} style={{ '--tonic': tonic } as customCSS}>
          <h5>Signals-communicated</h5>
          <div>
            {[...characteristic.signalsCommunicated,
              ...characteristic.signalsCommunicated]
              .map((d: { value: string, _id: string, }, index: number) => (
              <div className={styles.item} key={`signalCommunicated-${d._id}-${index}`}>
                <p style={{ color: textColor }}>{d.value}</p>
              </div>
              ))}
          </div>
        </div>
      )}
      {characteristic.semioticSocials.length > 0 && (
        <div className={styles.semioticSocials}>
          <h5>
            <img src={iconWine} alt='connection to wine & spirits' />
            Connection to wine & spirits
          </h5>
          {characteristic.semioticSocials.map((d: { value: string, _id: string }) => (
            <div className={styles.item} key={`keyTakeOut-${d._id}`}>
              <p>{d.value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Moodboard;
