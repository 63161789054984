import {
  SECTORS_GET,
  SECTOR_GET,
  SECTOR_SET,
  SECTOR_LIST_GET,
  SECTOR_POST,
  SECTOR_PUT,
  SECTOR_DELETE,
  SECTOR_LOADING,
  SECTOR_ERROR,
  CHARACTERISTIC_POST,
  CHARACTERISTIC_PUT,
  CHARACTERISTIC_DELETE,
} from '../actions/types';
import { ActionReducer } from '../types';
import ISector from '../types/sector';

interface ISectorState {
  sector: ISector | null,
  sectors: ISector[],
  list: any | null,
  isLoading: string[],
  error: string | null,
  changedAt: number,
}

const DEFAULT_STATE: ISectorState = {
  sector: null,
  sectors: [],
  list: {},
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const sectorReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const updateSectors: any = state.sectors || [];
  const sectorIndex = (updateSectors || []).findIndex(
    (p: any) => p._id === action.payload?.sector?._id,
  );
  switch (action.type) {
    case SECTOR_GET:
    case SECTOR_SET:
      updatedState = {
        ...state,
        sector: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== SECTOR_GET),
        error: null,
      };
      break;
    case SECTORS_GET:
      updatedState = {
        ...state,
        sector: null,
        sectors: action.payload.sectors,
        isLoading: state.isLoading?.filter((d) => d !== SECTORS_GET),
        error: null,
      };
      break;
    case SECTOR_LIST_GET:
      updatedState = {
        ...state,
        list: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== SECTOR_LIST_GET),
        error: null,
      };
      break;
    case SECTOR_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        sector: action.payload,
        sectors: [action.payload, ...state.sectors].sort(
          (a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime(),
        ),
        isLoading: state.isLoading?.filter((d) => d !== SECTOR_POST),
        error: null,
      };
      break;
    case SECTOR_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        sectors: state.sectors.filter((sector: any) => sector._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== SECTOR_DELETE),
        error: null,
      };
      break;
    case SECTOR_PUT:
    case CHARACTERISTIC_PUT:
    case CHARACTERISTIC_POST:
    case CHARACTERISTIC_DELETE:
      if (typeof sectorIndex === 'number') {
        updateSectors[sectorIndex] = action.payload.sector;
      }

      updatedState = {
        ...state,
        sector: action.payload.sector,
        sectors: updateSectors.sort(
          (a: any, b: any) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime(),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `SECTOR_PUT-${action.payload.sector._id}`,
        ),
        error: null,
      };
      break;
    case SECTOR_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SECTOR_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà associée à un compte';
      } else if (message === 'Unauthorized to create an sector with this role') {
        message = 'Vous n\'êtes pas autoriser à assigner ce rôle';
      }
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default sectorReducer;
