import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  useNavigate, Outlet, useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  useForm, FormProvider, useFormContext, FieldValues,
} from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import { SECTORS_GET, SECTOR_POST, SECTOR_PUT } from '../../actions/types';
import { getSectors, updateSector } from '../../actions/sectors';
import styles from './sector-edit.module.scss';
import ISector from '../../types/sector';
import { IFile } from '../../types/files';
import SectorNav from '../../components/SectorNav';
import Loader from '../../components/Loader';

const ContainerTitle = (
  {
    submit,
    isLoading,
    sectorID,
  } : {
    submit: (data: FieldValues) => Promise<void>,
    isLoading: string[],
    sectorID?: string,
  },
) => {
  const navigate = useNavigate();
  const {
    formState: { isValid, isDirty },
    watch,
    handleSubmit,
  } = useFormContext();
  const name = watch('name');
  const description = watch('description');
  const baseline = watch('baseline');

  // eslint-disable-next-line max-len
  const asMinimumRequirement = useMemo(() => (!!((name && description && baseline))), [name, description, baseline]);

  return (
    <div className={styles.containerTitle}>
      <div className={styles.back} onClick={() => {
        if (sectorID) navigate(`/sectors/${sectorID}`);
        else navigate('/luxury-codes');
      }}>
        <BsArrowLeft size={32} />
      </div>
      <h1>{watch('name') || '...'}</h1>
      <button
        onClick={handleSubmit(submit)}
        disabled={!isValid || !asMinimumRequirement || !isDirty}
        className={(!isValid || !asMinimumRequirement || !isDirty) ? 'disabled' : ''}
      >
        Save the luxury code
      </button>
      {((sectorID && isLoading.includes(`${SECTOR_PUT}-${sectorID}`))
      || (!sectorID && isLoading.includes(SECTOR_POST))) && (
        <div className={styles.containerLoader}>
          <Loader small />
        </div>
      )}
    </div>
  );
};

const SectorEdit = () => {
  const params = useParams();
  const sectorID = params.id;
  const dispatch = useDispatch();
  const { changedAt, sectors, isLoading } = useSelector((state: any) => state.sectorsReducer);

  const [sector, setSector] = useState<ISector>();

  const methods = useForm({
    defaultValues: {
      examples: [],
    } as FieldValues,
  });

  // GET SECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    }
  }, [sectors]);

  // GET SECTORS
  useEffect(() => {
    if (sectors.length === 0 && !isLoading.includes(SECTORS_GET)) {
      getSectors(dispatch);
    }
  }, [sectors]);

  // SET SECTOR
  useEffect(() => {
    if (sectorID) {
      const found: ISector | undefined = sectors
        .find((c: ISector) => c._id === sectorID);

      if (!found) return;
      setSector(found);
      dispatch({
        type: 'THEME_SET_TONIC',
        payload: found.color,
      });
    }
  }, [changedAt, sectors]);

  // INIT FORM
  useEffect(() => {
    if (!sector) return;
    methods.reset({
      name: sector.name,
      baseline: sector.baseline,
      description: sector.description,
      socialSignals: sector.socialSignals,
      artOfLiving: sector.artOfLiving,
      from: sector.from,
      to: sector.to,
      picture: sector.picture?._id,
      consumerTypologies: {
        primary: {
          ...sector.consumerTypologies.primary,
          picture: sector.consumerTypologies.primary.picture?._id || null,
        },
        secondary: {
          ...sector.consumerTypologies.secondary,
          picture: sector.consumerTypologies.secondary.picture?._id || null,
        },
      },
      moodboard: sector.moodboard
        ? sector.moodboard.map((e: IFile) => ({
          picture: e?._id,
        }))
        : [],
      keyTakeOuts: sector?.keyTakeOuts || [],
      fr: sector?.fr || [],
      us: sector?.us || [],
      cn: sector?.cn || [],
      thumbnail: sector.thumbnail?._id,
    });
  }, [sector?.updatedAt]);

  const submit = async (data: FieldValues) => {
    const moodboard = data.moodboard?.map((e: any) => e.picture);

    updateSector(dispatch, {
      _id: sectorID,
      ...data,
      moodboard,
    });
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.edit}>
        <div className={styles.sidebar}>
          <ContainerTitle
            submit={submit}
            sectorID={sectorID}
            isLoading={isLoading}
          />
          <SectorNav baseUrl={`/sector-edit/${sectorID}`} />
        </div>
        <div className={styles.container}>
          <div className={styles.form}>
            {sectors.length > 0 && (
              <Outlet context={{ sector: sector?._id, submit }} />
            )}
            {isLoading.includes(SECTORS_GET) && (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default SectorEdit;
