import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import Header from '../Header';
import styles from './layout.module.scss';

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const Layout = ({ children }: { children: React.ReactNode }) => {
  const themeReducer = useSelector((d: any) => d.themeReducer);
  const { tonic } = themeReducer;
  return (
    <div className={styles.layout}>
      <div className={styles.containerGradients}>
        <div className={styles.gradients} style={{ '--tonic': tonic } as customCSS}>
          <div className={styles.tonic} />
          <div className={styles.tonic2} />
          <div className={styles.grey} />
          <div className={styles.grey2} />
        </div>
      </div>
      <Header />
      <div className={styles.content} style={{ '--tonic': tonic } as customCSS}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
