import React, { useEffect, useId } from 'react';
import { useLocation } from 'react-router-dom';
import Introduction from '../sections/introduction';
import Moodboard from '../sections/moodboard';
import CountryRevelance from '../sections/country-revelance';
import styles from './container-scroll.module.scss';

const ContainerScroll = () => {
  const introductionId = useId();
  const moodboardId = useId();
  const countryRevelanceId = useId();
  const { pathname } = useLocation();
  useEffect(() => {
    let target = document.getElementById(introductionId);
    if (/moodboard/.test(pathname)) {
      target = document.getElementById(moodboardId);
    } else if (/country-revelance/.test(pathname)) {
      target = document.getElementById(countryRevelanceId);
    }

    if (!target) return;

    const scrollOffsetY = (target?.offsetTop || 0) - 30;
    target.parentElement?.parentElement?.scrollTo(0, scrollOffsetY);
  }, [pathname]);

  return (
    <>
      <section data-section='introduction' id={introductionId} className={styles.section}>
        <Introduction />
      </section>
      <section data-section='moodboard' id={moodboardId} className={styles.section}>
        <Moodboard />
      </section>
      <section data-section="country-revelance" id={countryRevelanceId} className={styles.section}>
        <CountryRevelance />
      </section>
    </>
  );
};

export default ContainerScroll;
